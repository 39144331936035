import { appRoutes, foremanRoute } from '@utils/consts';
import { foremanSharedRoutes } from '../shared/routes';

export const foremanOtherRoutes = [
  {
    path: foremanRoute.other,
    children: [
      {
        path: appRoutes.base,
        lazy: async () => {
          const { ForemanOther } = await import('@pages/foreman/other');
          return {
            Component: () => <ForemanOther />,
          };
        },
      },
      foremanSharedRoutes,
    ],
  },
];
