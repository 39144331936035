import { SvgConsumer } from '@components/icons/svgConsumer';
import { AppTooltip } from '@components/ui/tooltip/tooltip';
import { useTranslation } from 'react-i18next';

export const JobTaskIntegrationTooltip = () => {
  const { t } = useTranslation('app', { keyPrefix: 'pages.job' });

  return (
    <AppTooltip side="top" align="start" context={t('integrationTooltip')}>
      <div className="flex h-6 w-6 items-center justify-center rounded border-neutral-400 bg-neutral-100 text-lg text-neutral-500 hover:border">
        <SvgConsumer id="integration" />
      </div>
    </AppTooltip>
  );
};
