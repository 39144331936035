import { forwardRef, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@components/ui/popover';
import { Calendar } from '@components/ui/calendar';
import { cn } from '@utils/cn';
import { SvgConsumer } from '@components/icons/svgConsumer';

export interface DatePickerProps {
  error?: string;
  placeholder?: string;
  value?: Date | null;
  onChange?: (newDate?: Date | null) => void;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  noText?: boolean;
  noRemove?: boolean;
}

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      placeholder,
      value,
      onChange,
      error,
      disabled,
      readOnly,
      className = '',
      noText = false,
      noRemove = false,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (newDate?: Date) => {
      if (!onChange) return;
      onChange(newDate || null);
      setIsOpen(false);
    };

    return (
      <Popover open={isOpen} onOpenChange={!disabled ? setIsOpen : undefined}>
        <PopoverTrigger asChild>
          <div
            className={cn(
              'group-[.error]:shadow-danger-b flex h-8 w-full items-center gap-2 rounded border border-neutral-300 bg-white px-3 text-m text-neutral-800 focus-within:border-primary-600 focus-within:outline focus-within:outline-2 focus-within:outline-primary-200',
              error ? 'border-danger-500 focus-within:border-danger-500' : '',
              disabled ? 'border-neutral-200 bg-neutral-50 text-neutral-400' : '',
              readOnly ? 'border-neutral-300 text-neutral-800' : '',
              className,
            )}>
            <button
              className="flex w-full items-center justify-between outline-transparent disabled:cursor-not-allowed"
              type="button"
              disabled={disabled}>
              {!noText && (
                <>
                  {value ? (
                    value.toLocaleDateString()
                  ) : (
                    <span className="text-neutral-400">{placeholder}</span>
                  )}
                </>
              )}
              {!noRemove && value ? (
                <button
                  className="text-sm text-neutral-400 hover:text-danger-500 disabled:text-neutral-200"
                  disabled={disabled}
                  onClick={(e) => {
                    if (!onChange) return;
                    e.stopPropagation();
                    onChange(null);
                  }}
                  type="button">
                  <SvgConsumer id="trash" />
                </button>
              ) : (
                <SvgConsumer
                  id="calendar"
                  className={cn([
                    'h-3.5 w-auto',
                    noText ? 'text-inherit' : 'text-neutral-400',
                    !disabled ? 'text-neutral-400' : 'text-neutral-200',
                  ])}
                />
              )}
            </button>
          </div>
        </PopoverTrigger>
        <PopoverContent ref={ref} className="w-auto p-0">
          <Calendar
            mode="single"
            selected={value || undefined}
            onSelect={handleSelect}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    );
  },
);

export { DatePicker };
