import { Footer } from '@components/footer';
import { SvgSources } from '@components/icons/svgSources';
import { Navbar } from '@components/navbar';
import { Toaster } from '@components/ui/toast/toaster';
import { useAppContext } from '@contexts/app';
import { useWebsocketContext } from '@contexts/websocket';
import { useGetDropdownsData } from '@services/dropdowns';
import { useRevalidateQuery } from '@services/revalidateQuerys';
import { cn } from '@utils/cn';
import { appRoutes, foremanRoute } from '@utils/consts';
import { handleLanguageChange } from '@utils/i18n';
import { formatResponse } from '@utils/textHelpers';
import { useEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation, useParams } from 'react-router-dom';

type Message = {
  action?: string[];
  hotSeatAlerts?: boolean;
  onDeckAlerts?: boolean;
};

export const Layout = () => {
  const { isLogged, isManager, id, pushEnabled, setData } = useAppContext();
  const { revalidate } = useRevalidateQuery();
  const { pathname } = useLocation();
  const { lang } = useParams();
  const { ws } = useWebsocketContext();

  const notFound = pathname.includes(appRoutes.notFound);
  const isFullScreen = pathname.includes(appRoutes.map);

  useEffect(() => {
    if (!id) return;
    const handleMessageActions = (msg: Message) => {
      const transformedMsg = formatResponse(msg);
      if (transformedMsg.action) {
        revalidate(transformedMsg.action).catch((err) => {
          console.error(err);
        });
      }
      if (typeof setData === 'function') {
        setData((ps) => ({
          ...ps,
          onDeckError: transformedMsg?.onDeckAlerts || false,
          hotSeatError: transformedMsg?.hotSeatAlerts || false,
        }));
      }
    };

    // const UserChannel = ws?.subscriptions?.create(
    //   {
    //     channel: 'RefreshChannel',
    //     room: id,
    //   },
    //   {
    //     connected() {
    //       // eslint-disable-next-line no-console
    //       console.info('Main channel connected');
    //     },
    //     disconnected() {
    //       // eslint-disable-next-line no-console
    //       console.info('Main channel connection lost');
    //     },
    //     received(data: Message) {
    //       handleMessageActions(data);
    //     },
    //   },
    // );
    const OfficeChannel = isManager
      ? ws?.subscriptions?.create(
          {
            channel: 'RefreshChannel',
          },
          {
            connected() {
              // eslint-disable-next-line no-console
              console.info('Sub channel connected');
            },
            disconnected() {
              // eslint-disable-next-line no-console
              console.info('Sub channel connection lost');
            },
            received(data: Message) {
              handleMessageActions(data);
            },
          },
        )
      : null;
    ws?.connect();

    return () => {
      // UserChannel?.disconnected();
      OfficeChannel?.disconnected();
    };
  }, [id, isManager, revalidate, setData, ws]);

  useGetDropdownsData();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleLanguageChange(lang || 'en');
  }, [lang]);

  return (
    <div
      className={`flex min-h-screen w-full flex-col ${isLogged && !notFound ? '' : 'login-page'}`}>
      <SvgSources />
      <Navbar />
      <main
        key={pushEnabled ? new Date().toISOString() : ''}
        className={cn(
          'main mx-auto w-full pb-5',
          isLogged && !notFound ? 'grow' : 'pt-10',
          !isFullScreen ? '2xl:max-w-[1400px] 2xl:px-4 2xl:py-5' : 'py-5',
        )}>
        <Outlet />
      </main>
      <Footer isCompact={!isLogged || notFound} />
      <ScrollRestoration
        getKey={(location) =>
          location.pathname.includes(foremanRoute.index) ? location.pathname : location.key
        }
      />
      <Toaster />
    </div>
  );
};
