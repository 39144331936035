import { cn } from '@utils/cn';
import { SchedulerGridScheduleResizeProps } from './types';
import { useSchedulerResize } from '@hooks/scheduler/resize';
import { useSchedulerContext } from '@contexts/scheduler';

export const SchedulerGridScheduleResize = ({ direction }: SchedulerGridScheduleResizeProps) => {
  const { inventory } = useSchedulerContext();
  const { handlePointerDown } = useSchedulerResize();

  return (
    <button
      disabled={inventory}
      type="button"
      onPointerDown={() => handlePointerDown(direction)}
      className={cn([
        'absolute z-20 h-full cursor-ew-resize px-[1px] after:block after:h-full after:w-1 after:bg-neutral-700 disabled:cursor-move',
        direction === 'left' ? '-left-[1px]' : '-right-[1px]',
      ])}
    />
  );
};
