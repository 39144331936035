import { useQueryParams } from '@hooks/queryParams';
import { addDays, isToday, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { UseDateProps } from './types';
import { formatDate, parseDate } from '@utils/dateHelpers';

export const useDate = (options: UseDateProps = {}) => {
  const { initialDate, disableLoadFromParams, disableParamsUpdate } = options;
  const { params, setParam } = useQueryParams();
  const [state, setState] = useState<Date>(
    params?.date && !disableLoadFromParams ? parseDate(params.date) : initialDate || new Date(),
  );

  const [date] = useDebounce(state, 500);

  const handlePrevDay = () => setState((prev) => subDays(prev, 1));
  const handleNextDay = () => setState((prev) => addDays(prev, 1));

  useEffect(() => {
    if (disableParamsUpdate) return;
    if (!date) return;
    if (isToday(date) && !params?.date) return;
    setParam('date', formatDate(date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return { date, rawDate: state, setRawDate: setState, handlePrevDay, handleNextDay };
};
