import { appRoutes, foremanRoute } from '@utils/consts';
import { foremanSharedRoutes } from '@pages/foreman/shared/routes';

export const foremanRequestsRoutes = [
  {
    path: foremanRoute.requests,
    children: [
      {
        path: appRoutes.base,
        lazy: async () => {
          const { ForemanRequests } = await import('@pages/foreman/requests');
          return {
            Component: () => <ForemanRequests />,
          };
        },
      },
      foremanSharedRoutes,
    ],
  },
];
