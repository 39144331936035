import { useMutation } from '@tanstack/react-query';
import { FiltersReq, FiltersRespSuccess } from './types';
import { queryFetch } from '@services/api';
import { useAppContext } from '@contexts/app';

export const useUpdateFilters = () => {
  const { id, setData } = useAppContext();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ data }: FiltersReq): Promise<FiltersRespSuccess> =>
      queryFetch({
        url: `users/${id}/update_filters`,
        data: { user: { filters: data } },
        opt: { method: 'PUT' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ data, success }) => {
      if (!success || !data || !setData) return;
      setData((prev) => ({ ...prev, filters: data }));
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
