import { useEffect } from 'react';
import { parseISO } from 'date-fns';
import { formatTime } from '@utils/i18n';
import { cn } from '@utils/cn';
import { activeStyles, getPillStatus, styles } from '@components/workload/calendar/row/job/helpers';
import { SchedulerGridScheduleMenu } from '../menu';
import { SchedulerGridScheduleElementProps } from './types';
import { SchedulerGridScheduleEdge } from '../edge';
import { useSchedulerContext } from '@contexts/scheduler';
import { useElementStyle } from '@hooks/scheduler/elementStyle';
import { SchedulerGridScheduleResize } from '../resize';
import { WorkloadBasicSpeciality } from '@services/workload/calendar/types';
import { useDndMonitor, useDraggable } from '@dnd-kit/core';
import { useSchedulerDragAndDrop } from '@hooks/scheduler/dnd';

export const SchedulerGridScheduleElement = ({
  data,
  tooltip,
  setIsTruncated,
}: SchedulerGridScheduleElementProps) => {
  const {
    active: { schedule, mode, newValues },
  } = useSchedulerContext();

  const { handleDragStart, handleDragMove, handleDragEnd, handlePointer } =
    useSchedulerDragAndDrop();

  const { id, task, warehouseTask, startsAt, endsAt, status, foreman } = data;
  const name = task?.name || warehouseTask?.name || '';
  const address = task?.job?.address || warehouseTask?.warehouse?.address || '';
  const isActiveSchedule = !tooltip && schedule?.id === id;

  const { attributes, listeners, setNodeRef, transform, node } = useDraggable({
    id,
    attributes: { role: 'div' },
    disabled: !isActiveSchedule || mode !== 'move',
  });

  useDndMonitor(
    isActiveSchedule && mode === 'move'
      ? {
          onDragStart: () => handleDragStart(data),
          onDragEnd: () => handleDragEnd(foreman.id),
          onDragMove: (e) => handleDragMove(e),
        }
      : {},
  );

  const elementStyle = useElementStyle(data?.id || 'new', isActiveSchedule);
  const hasLeftEdge =
    !isActiveSchedule && !tooltip && data?.task?.multischedule && !data?.task?.multischeduleFirst;
  const hasRightEdge =
    !isActiveSchedule && !tooltip && data?.task?.multischedule && !data?.task?.multischeduleLast;

  useEffect(() => {
    const element = node?.current;
    if (!element || !setIsTruncated || isActiveSchedule) return;
    const texts = Array.from(element.getElementsByTagName('span'));
    setIsTruncated(texts.some((el) => el.offsetWidth < el.scrollWidth));
  }, [node, elementStyle, setIsTruncated, isActiveSchedule]);

  if (!tooltip && !elementStyle) return null;

  const getSpeciality = (): WorkloadBasicSpeciality => {
    const taskName = name.toLocaleLowerCase();
    if (taskName.includes('layout')) return 'layout';
    if (taskName.includes('wall')) return 'walls';
    if (taskName.includes('scaffold trailer')) return 'utilityDriver';
    if (taskName.includes('cs team')) return 'customerService';
    return taskName.split(' ')[0] as WorkloadBasicSpeciality;
  };

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const { regular, menu } = isActiveSchedule
    ? activeStyles
    : styles[getSpeciality()][getPillStatus(startsAt, status)];

  const startHour =
    isActiveSchedule && mode !== 'move' && newValues?.startsAt
      ? formatTime(newValues.startsAt)
      : formatTime(parseISO(startsAt));
  const endHour =
    isActiveSchedule && mode !== 'move' && newValues?.endsAt
      ? formatTime(newValues?.endsAt)
      : formatTime(parseISO(endsAt));

  return (
    <div
      onPointerEnter={(e) => handlePointer(isActiveSchedule, 'enter', e.clientX, e.clientY)}
      onPointerLeave={() => handlePointer(isActiveSchedule, 'leave')}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={cn([
        'z-10 flex flex-col justify-between whitespace-nowrap rounded border p-1 leading-tight tracking-tight shadow-task',
        tooltip ? 'h-[43px] px-2' : 'absolute bottom-[3px] pl-2',
        isActiveSchedule &&
          'scheduler-active-schedule z-20 h-[calc(50%-2.5px)] justify-center px-2 py-0',
        regular,
      ])}
      style={!tooltip && elementStyle ? { ...elementStyle, ...style } : undefined}>
      {isActiveSchedule ? (
        <>
          <div className="relative z-10 mt-[1px] flex h-full items-center justify-between gap-1">
            <span className="overflow-hidden whitespace-nowrap font-bold">{address}</span>
            <span className="overflow-hidden whitespace-nowrap text-xs leading-none">
              {startHour} - {endHour}
            </span>
          </div>
          <SchedulerGridScheduleResize direction="left" />
          <SchedulerGridScheduleResize direction="right" />
        </>
      ) : (
        <>
          <div className="relative flex justify-between gap-1 text-xs">
            <span className="overflow-hidden">{name}</span>
            <span className={cn(['overflow-hidden', !tooltip && 'mr-5'])}>
              {startHour} - {endHour}
            </span>
            {!tooltip && (
              <SchedulerGridScheduleMenu
                data={data}
                className={cn([
                  menu,
                  Number(elementStyle?.width || 0) < 36 && 'left-1/2 -translate-x-1/2',
                ])}
              />
            )}
          </div>
          <span className="relative z-10 overflow-hidden font-bold leading-none">{address}</span>
          {hasLeftEdge && <SchedulerGridScheduleEdge position="left" styles={regular} />}
          {hasRightEdge && <SchedulerGridScheduleEdge position="right" styles={regular} />}
        </>
      )}
    </div>
  );
};
