import { useQuery } from '@tanstack/react-query';
import { ApiError } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { FiltersRespSuccess } from './types';
import { useAppContext } from '@contexts/app';

export const useGetFilters = () => {
  const { id } = useAppContext();

  const { data, error, isFetching } = useQuery<FiltersRespSuccess, ApiError>({
    queryKey: queryKeys.filters,
    queryFn: () => queryFetch({ url: `users/${id}/filters` }),
    enabled: Boolean(id && uuidRegEx.test(id)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
