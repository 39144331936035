import { toast } from '@hooks/useToast';
import { Button } from '@components/ui/button';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '@contexts/app';
import { useCreateWebPush } from '@services/webPushNotifications/create';
import { DefaultApiResp } from '@root/globalTypes';

export const PushNotifications = () => {
  const { t } = useTranslation('app');
  const { setData } = useAppContext();
  const onSettled = (resp: DefaultApiResp) => {
    if (resp.success) {
      toast({
        variant: 'default',
        title: t('navigation.push.confirmed'),
      });
      if (setData)
        setData((ps) => ({
          ...ps,
          pushEnabled: true,
        }));
    }
  };
  const { mutate } = useCreateWebPush({ callback: onSettled });
  if (!window.location.protocol.includes('s')) return null;
  function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      // eslint-disable-next-line immutable/no-mutation
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  const enablePushNotifications = async () => {
    if ('Notification' in window) {
      await Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          try {
            const options = {
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(import.meta.env.VITE_PUSH_KEY as string),
            };
            void navigator.serviceWorker.ready.then(async (serviceWorkerRegistration) => {
              await serviceWorkerRegistration.pushManager
                .subscribe(options)
                .then((pushSubscription) => {
                  const { endpoint, keys } = pushSubscription.toJSON();
                  if (typeof endpoint !== 'string' || !keys) return;
                  const { auth, p256dh } = keys;
                  mutate({
                    webpushNotifications: {
                      endpoint: endpoint,
                      auth: auth,
                      p256dh: p256dh,
                    },
                  });
                })
                .finally(() => {
                  window.location.reload();
                });
            });
          } catch (error) {
            console.error(error);
          }
        }
      });
    }
  };

  if (
    'Notification' in window &&
    Notification.permission !== 'granted' &&
    import.meta.env.VITE_PUSH_KEY
  )
    return (
      <div className="bg-warning ">
        <div className="container flex items-center justify-between px-4 py-1 text-white">
          <span className="font-inter text-m font-medium">{t('navigation.push.info')}</span>
          <Button variant="secondary" size="sm" onClick={() => void enablePushNotifications()}>
            {t('navigation.push.action')}
          </Button>
        </div>
      </div>
    );

  return null;
};
