import { useElementMeasures } from '@hooks/useElementMeasures';
import { useCallback, useEffect } from 'react';
import { useQueryParams } from './queryParams';

export const useJobStages = () => {
  const {
    params: { focus, ...params },
    setParams,
  } = useQueryParams();
  const openedStages = params.open || [];
  const headerHeight = useElementMeasures('header').height;
  const jobHeaderHeight = useElementMeasures('sticky-job-header').height;

  const isOpen = (id: string) => openedStages.includes(id.toString());

  const scrollToStage = useCallback(
    (id: string) => {
      const stage = document.querySelector(`#stage-${id}`) as HTMLElement;
      const marginTop = 8;

      scrollTo({
        top:
          window.scrollY +
          stage?.getBoundingClientRect().top -
          headerHeight -
          jobHeaderHeight -
          marginTop,
        behavior: 'smooth',
      });
    },
    [headerHeight, jobHeaderHeight],
  );

  const open = (id: string, withScroll?: boolean) => {
    if (!isOpen(id)) {
      const stagesWithNewIncluded = [...openedStages, id.toString()];
      setParams({ open: stagesWithNewIncluded, focus: id }, { preventScrollReset: true });
    }
    if (withScroll) scrollToStage(id.toString());
  };

  const close = (id: string) => {
    const stagesWithCurrentExcluded = openedStages.filter((el) => el !== id.toString());
    setParams(
      {
        open: stagesWithCurrentExcluded.length ? stagesWithCurrentExcluded : undefined,
        focus: undefined,
      },
      { preventScrollReset: true },
    );
  };

  const toggle = (id: string) => {
    if (isOpen(id)) close(id);
    else open(id);
  };

  useEffect(() => {
    if (!focus) return;
    scrollToStage(focus);
  }, [focus, scrollToStage]);

  return { open, toggle, isOpen };
};
