import { useAppContext } from '@contexts/app';
import { UserDropdownItem } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { useIsFetching, useQueries, UseQueryResult } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useEffect } from 'react';
import { BuildersDropdownRespSuccess, UsersDropdownRespSuccess } from './types';

type DropdownsData = {
  coordinators: UserDropdownItem[];
  superintendents: UserDropdownItem[];
  builders: UserDropdownItem[];
};

export const useGetDropdownsData = () => {
  const { isLogged, isForeman, setData } = useAppContext();
  const isFetchingDropdowns = useIsFetching({ queryKey: ['dropdowns'] });
  const queriesList = ['builders', 'project_coordinator', 'superintendent'].map((u) => {
    if (u === 'builders')
      return {
        key: queryKeys.buildersDropdown,
        fn: () => queryFetch({ url: 'dropdowns/builders' }),
      };
    if (u === 'project_coordinator')
      return {
        key: queryKeys.projectCoordinator,
        fn: () =>
          queryFetch({ url: 'dropdowns/users?role[]=project_coordinator&role[]=administrator' }),
      };
    if (u === 'superintendent')
      return {
        key: queryKeys.superintendent,
        fn: () => queryFetch({ url: 'dropdowns/users?role[]=superintendent' }),
      };
    return null;
  });

  const results = useQueries({
    queries:
      isLogged && !isForeman
        ? queriesList
            .filter((el) => el?.key)
            .map((el) => ({
              key: el?.key,
              queryKey: ['dropdowns', el?.key],
              queryFn: el?.fn,
              staleTime: 10 * 1000,
              cacheTime: 20 * 1000,
            }))
        : [],
  });

  function sortResults(
    r: UseQueryResult<BuildersDropdownRespSuccess | UsersDropdownRespSuccess, Error>[],
  ): DropdownsData {
    const coordinators: UserDropdownItem[] = [];
    const superintendents: UserDropdownItem[] = [];
    const builders: UserDropdownItem[] = [];

    r.forEach((res) => {
      if (!res.data?.records) return;
      res.data.records.forEach((rec) => {
        if (rec?.role) {
          if (rec.role === 'superintendent') {
            superintendents.push(rec);
          } else {
            coordinators.push(rec);
          }
        } else {
          builders.push(rec);
        }
      });
    });

    return {
      coordinators,
      superintendents,
      builders,
    };
  }

  useEffect(() => {
    if (!isLogged || isForeman) return;
    if (isFetchingDropdowns > 0) return;
    if (typeof setData === 'function') {
      setData((prev) => ({
        ...prev,
        ...sortResults(
          results as UseQueryResult<
            BuildersDropdownRespSuccess | UsersDropdownRespSuccess,
            Error
          >[],
        ),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingDropdowns, setData, isLogged, isForeman]);
};
