import { cn } from '@utils/cn';
import { LoaderSpinner } from './spinner';
import { LoaderProps } from './types';

export const Loader = ({ local }: LoaderProps) => (
  <div
    className={cn(
      'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-black',
      local ? 'absolute' : 'fixed',
    )}>
    <LoaderSpinner />
  </div>
);
