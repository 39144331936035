import { useQuery } from '@tanstack/react-query';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { queryFetch } from '@services/api';
import { ApiError, LocationsDropdownItem } from '@root/globalTypes';
import { LocationItemsRespSuccess } from '../types';
import { useQueryParams } from '@hooks/queryParams';

export const useGetLocationItems = (location: LocationsDropdownItem) => {
  const { parseParams } = useQueryParams();
  const { locationId, locationType } = location;

  const queryParams = parseParams({ locationId, locationType });

  const { data, error, isFetching } = useQuery<LocationItemsRespSuccess, ApiError>({
    queryKey: [...queryKeys.locationItems, locationId, locationType],
    queryFn: () => queryFetch({ url: `inventories/preview${queryParams}` }),
    placeholderData: (previousData) => previousData as LocationItemsRespSuccess,
    enabled: Boolean(locationId && uuidRegEx.test(locationId)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
