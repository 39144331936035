import { Button } from '@components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@components/ui/dialog';
import { Form, FormControl, FormField, FormMessage } from '@components/ui/form';
import { Input } from '@components/ui/form/input';
import { FormItemWrapper } from '@components/ui/form/itemWrapper';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@components/ui/form/select';
import { useUploadFileForm } from '@hooks/forms/useUploadFileForm';
import { useToast } from '@hooks/useToast';
import { useGetSubtasksDropdown } from '@services/documents/dropdown';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Document } from '@root/globalTypes';
import { useFileInput } from '@hooks/useFileInput';
import { FileInput } from '@components/ui/form/file';
import { cn } from '@utils/cn';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { DocumentsQueryType } from '@services/documents';

interface UploadFileDialogProps {
  jobId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  initialValues?: Document;
  initialSubtaskId?: string;
  title?: string;
  dragAndDrop?: boolean;
  queryType?: DocumentsQueryType;
}

export const UploadFileDialog = ({
  jobId,
  open,
  setOpen,
  initialValues,
  initialSubtaskId,
  title,
  dragAndDrop,
  queryType = 'documents',
}: UploadFileDialogProps) => {
  const { toast } = useToast();
  const { t } = useTranslation('app');

  const subtasks = useGetSubtasksDropdown({ jobId });

  const fileInputProps = useFileInput();
  const { files, setFiles } = fileInputProps;

  const { form, onSubmit, locked } = useUploadFileForm({
    id: initialValues?.id,
    jobId,
    files,
    queryType,
    successHandler: () => {
      form.reset();
      setFiles(null);
      setOpen(false);
      toast({
        title: t(`messages.success.file.${initialValues ? 'update' : 'upload'}`),
      });
    },
  });

  const { setValue, reset } = form;
  const subtaskId = form.watch('subtaskId');
  const selectedSubtask =
    subtaskId !== 'other' ? subtasks.data?.records?.find((el) => el.id === subtaskId) : undefined;

  const getAccept = () => {
    return (
      selectedSubtask?.allowedContentTypes
        ?.map((type) => (type === 'application/dwg' ? '.dwg' : type))
        .join(' ') || '*'
    );
  };

  useEffect(() => {
    if (!open) setFiles(null);
    if (!initialValues) reset();
    else {
      setValue('subtaskId', initialValues.subtaskId || 'other');
      if (!initialValues.subtaskId) setValue('name', initialValues?.name || '');
    }
    if (initialSubtaskId) setValue('subtaskId', initialSubtaskId);
  }, [initialValues, open, setValue, reset, setFiles, initialSubtaskId]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title || t('fileUpload.title')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full space-y-4">
            <div
              className={cn([
                'flex gap-4 [&>*]:w-full',
                !initialSubtaskId && '[&>*]:max-w-[223px]',
              ])}>
              {!initialSubtaskId && (
                <FormField
                  control={form.control}
                  name="subtaskId"
                  render={({ field: { value, onChange } }) => (
                    <FormItemWrapper label={t('forms.documentType.label')} select>
                      <Select
                        name="subtaskIdSelect"
                        value={value}
                        onValueChange={onChange}
                        disabled={
                          Boolean(!subtasks?.data?.records?.length) ||
                          Boolean(initialValues) ||
                          subtasks.isLoading
                        }>
                        <FormControl>
                          <SelectTrigger name="subtaskId">
                            <SelectValue
                              id="subtaskId"
                              placeholder={t('forms.default.select.placeholder')}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {subtasks?.data?.records
                            ?.concat([{ id: 'other', name: t('pages.job.documents.other') }])
                            .map(({ id, name }) => (
                              <SelectItem key={id as string} value={id as string}>
                                {name}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormItemWrapper>
                  )}
                />
              )}
              {subtaskId === 'other' && (
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItemWrapper label={t('forms.documentName.label')}>
                      <Input {...field} />
                    </FormItemWrapper>
                  )}
                />
              )}
            </div>

            <FileInput
              {...fileInputProps}
              accept={getAccept()}
              multiple={!initialValues}
              dragAndDrop={dragAndDrop}
            />

            <FormField name="root" render={() => <FormMessage />} />
            <div className="flex items-center justify-end gap-2">
              <Button variant="secondary" type="button" onClick={() => setOpen(false)}>
                {t('actions.cancel')}
              </Button>
              <Button
                type="submit"
                disabled={Boolean(!files || !Array.from(files).length) || locked}>
                {initialSubtaskId && (
                  <span>
                    <SvgConsumer className="text-m" id="jobCompleted" />
                  </span>
                )}
                {t(`actions.${initialSubtaskId ? 'confirm' : 'save'}`)}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
