import { DefaultApiResp } from '@root/globalTypes';
import { queryFetch } from '../api';
import { useMutation } from '@tanstack/react-query';
import { WebPushReq } from './types';
import { useShowErrorToast } from '@utils/formHelpers';

export const useCreateWebPush = ({ callback }: { callback: (data: DefaultApiResp) => void }) => {
  const { showErrorToast } = useShowErrorToast();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ ...data }: WebPushReq): Promise<DefaultApiResp> =>
      queryFetch({ url: 'webpush_notifications', data, opt: { method: 'POST' } }),
    onError: (err) => {
      console.error(err);
    },
    onSettled: (data) => {
      const { success, errors } = data as DefaultApiResp;
      if (errors) showErrorToast(errors);
      if (success) callback(data as DefaultApiResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
