import { useSchedulerContext } from '@contexts/scheduler';
import { useScheduler } from '@hooks/scheduler';
import { Schedule } from '@services/jobs/schedules/teams';
import { getSegment, getSegmentFromPoint } from './helpers';
import { DragMoveEvent } from '@dnd-kit/core';
import { parseISO } from 'date-fns';

export const useSchedulerDragAndDrop = () => {
  const {
    inventory,
    active: { mode },
    setState,
  } = useSchedulerContext();
  const { updateDates } = useScheduler();

  const handleDragStart = (schedule: Schedule) => {
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        mode: 'move',
        newValues: {
          ...prev.active.newValues,
          startsAt: parseISO(schedule.startsAt),
          endsAt: parseISO(schedule.endsAt),
        },
      },
    }));
  };

  const handleDragEnd = (id: string) => {
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        fresh: false,
        mode: 'move',
        schedule: {
          ...prev.active.schedule,
          foreman: { ...prev.active.schedule.foreman, id },
          startsAt: !inventory
            ? prev.active.newValues.startsAt?.toISOString() || prev.active.schedule.startsAt
            : prev.active.schedule.startsAt,
          endsAt: !inventory
            ? prev.active.newValues.endsAt?.toISOString() || prev.active.schedule.endsAt
            : prev.active.schedule.endsAt,
        },
      },
    }));
  };

  const handleDragMove = (e: DragMoveEvent) => {
    const event = e.activatorEvent as MouseEvent & TouchEvent;
    if (!event) return;

    const [clientX, clientY] = [
      (event?.touches ? event.touches[0].clientX : event.clientX) + e.delta.x,
      (event?.touches ? event.touches[0].clientY : event.clientY) + e.delta.y,
    ];

    const foremanId = getSegment(clientX, clientY)?.getAttribute('data-foreman');
    const to = getSegmentFromPoint(clientX, clientY);

    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        newValues: {
          ...prev.active.newValues,
          ...updateDates(),
          foremanId: typeof foremanId === 'string' ? foremanId : prev.active.newValues.foremanId,
          from: prev.active.newValues.from || to,
          to,
        },
      },
    }));
  };

  const handlePointer = (active: boolean, type: 'enter' | 'leave', x?: number, y?: number) => {
    if (!active || mode !== 'move') return;

    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        cursor: type === 'enter' ? 'cursor-move' : 'cursor-auto',
        newValues: {
          ...prev.active.newValues,
          foremanId:
            !prev.active.newValues.foremanId && x && y
              ? getSegment(x, y)?.getAttribute('data-foreman') || ''
              : prev.active.newValues.foremanId,
          from:
            !prev.active.newValues.from && x && y
              ? getSegmentFromPoint(x, y)
              : prev.active.newValues.from,
        },
      },
    }));
  };

  return { handleDragStart, handleDragMove, handleDragEnd, handlePointer };
};
