import { useSchedulerContext } from '@contexts/scheduler';
import { SchedulerGridRowProps } from './types';
import { SchedulerGridForeman } from './foreman';
import { SchedulerGridCell } from '../cell';
import { SchedulerGridCellSegment } from './segment';
import { cn } from '@utils/cn';
import { getSchedule, isEnd, isStart } from './helpers';
import { SEGMENT_WIDTH } from '@contexts/scheduler/constants';

export const SchedulerGridRow = ({
  data,
  first,
  last,
  lastRow,
  foreman: { id, name },
  withoutTeam,
}: SchedulerGridRowProps) => {
  const { hours, modal } = useSchedulerContext();

  return (
    <>
      <SchedulerGridForeman name={name} first={first} last={last} lastRow={lastRow} />
      {hours.map((hour, i) => (
        <SchedulerGridCell
          key={`scheduler-grid-cell-${i}`}
          className={cn([
            'scheduler-grid-cell',
            !lastRow || modal ? 'border-b' : 'after:hidden',
            !last && 'border-b-transparent',
            withoutTeam && 'bg-neutral-50 bg-opacity-50',
            i === hours.length - 1 && 'border-r',
          ])}>
          {hour.map((segment) => (
            <SchedulerGridCellSegment
              key={`scheduler-grid-cell-${i}-segment-${segment.getTime()}`}
              date={segment}
              start={data.length ? isStart(segment, data) : false}
              end={data.length ? isEnd(segment, data) : false}
              data={data.length ? getSchedule(segment, data) : undefined}
              last={last}
              foremanId={id}
              style={SEGMENT_WIDTH}
            />
          ))}
        </SchedulerGridCell>
      ))}
    </>
  );
};
