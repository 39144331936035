import { ProtectedRoute } from '@components/protectedRoute';
import { usersAccessList } from '@utils/consts';
import { buildersRoute as route } from '@utils/consts';
import { Outlet } from 'react-router-dom';

const routes = [
  {
    path: route.index,
    lazy: async () => {
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={usersAccessList(route.index)}>
            <Outlet />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        path: '',
        lazy: async () => {
          const { Builders } = await import('@pages/builders');
          return {
            Component: () => <Builders />,
          };
        },
      },
      {
        path: route.builder(),
        lazy: async () => {
          const { Builder } = await import('@pages/builders/:id');
          return {
            Component: () => <Builder />,
          };
        },
      },
    ],
  },
];

export { routes as buildersRoutes };
