import { useUser } from '@hooks/useUser';
import { createContext, useContext, useEffect, useState } from 'react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { modelUserData } from '@utils/userData';
import { AppContextData, AppData, AppProviderProps } from './types';
import { initialState } from './constants';
import { useGetUser } from '@services/users/get';

const AppContext = createContext<AppContextData | Record<string, never>>({});

export const AppProvider = ({ children }: AppProviderProps) => {
  const { user, isLogged } = useUser();

  const { data: userData } = useGetUser(user.id);
  const [data, setData] = useState<AppData>({ ...initialState, ...user });

  const fetching = useIsFetching();
  const mutating = useIsMutating();

  useEffect(() => {
    if (!userData) return;
    setData((prev) => ({
      ...prev,
      ...modelUserData(userData.data),
      onDeckError: userData.onDeckAlerts,
      hotSeatError: userData.hotSeatAlerts,
    }));
  }, [userData]);

  return (
    <AppContext.Provider
      value={{
        ...data,
        isLogged,
        setData,
        apiActivity: Boolean(fetching) || Boolean(mutating),
      }}>
      {children}
    </AppContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAppContext = () => useContext(AppContext);
