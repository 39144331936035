import { ProtectedRoute } from '@components/protectedRoute';
import { usersAccessList } from '@utils/consts';
import { usersRoute as route } from '@utils/consts';

const routes = [
  {
    path: route.index,
    children: [
      {
        path: '',
        lazy: async () => {
          const { Users } = await import('@pages/users');
          return {
            Component: () => (
              <ProtectedRoute allowedRoles={usersAccessList(route.index)}>
                <Users />
              </ProtectedRoute>
            ),
          };
        },
      },
      {
        path: route.acceptInvitation,
        lazy: async () => {
          const { AcceptInvitation } = await import('@pages/users/acceptInvitation');
          return {
            Component: () => <AcceptInvitation />,
          };
        },
      },
    ],
  },
];

export { routes as usersRoutes };
