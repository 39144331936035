import React from 'react';

const mapIcons = {
  mapJob: (
    <symbol id="mapJobIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 17">
      <path
        fill="currentColor"
        d="M3.292 17v-3.098h-2.1v-1.939h2.1V9.17H0L10.5 0 21 9.17h-3.292v2.793h2.1v1.939h-2.1V17h-1.995v-3.098h-4.215V17H9.503v-3.098H5.287V17H3.292Zm1.995-5.037h4.216V3.487L5.287 7.149v4.814Zm6.21 0h4.216V7.15l-4.215-3.662v8.476Z"
      />
    </symbol>
  ),
};

const jobIcons = {
  jobCompleted: (
    <symbol id="jobCompletedIcon" viewBox="0 0 18 16">
      <path
        fill="currentColor"
        d="M11.267 5.459 7.692 8.863 6.317 7.557a.83.83 0 0 0-.27-.198.869.869 0 0 0-.952.153.79.79 0 0 0-.187.27.756.756 0 0 0 .026.634c.05.099.12.186.208.257L7.1 10.54a.84.84 0 0 0 .592.23.856.856 0 0 0 .583-.23l4.167-3.958a.758.758 0 0 0 0-1.124.856.856 0 0 0-.588-.23.856.856 0 0 0-.587.23ZM9 .083c-1.648 0-3.259.465-4.63 1.335A8.02 8.02 0 0 0 1.302 4.97a7.55 7.55 0 0 0-.474 4.574 7.802 7.802 0 0 0 2.28 4.054 8.478 8.478 0 0 0 4.268 2.167 8.742 8.742 0 0 0 4.814-.451 8.255 8.255 0 0 0 3.74-2.916A7.638 7.638 0 0 0 17.334 8c0-1.04-.216-2.07-.635-3.03a7.903 7.903 0 0 0-1.806-2.568A8.376 8.376 0 0 0 12.189.686 8.716 8.716 0 0 0 9 .083Zm0 14.25a6.919 6.919 0 0 1-3.703-1.067 6.416 6.416 0 0 1-2.456-2.842 6.04 6.04 0 0 1-.38-3.66 6.242 6.242 0 0 1 1.825-3.242A6.782 6.782 0 0 1 7.7 1.788a6.993 6.993 0 0 1 3.852.36 6.606 6.606 0 0 1 2.992 2.333A6.11 6.11 0 0 1 15.666 8c0 1.68-.702 3.29-1.953 4.478-1.25 1.188-2.946 1.855-4.714 1.855Z"
      />
    </symbol>
  ),
  jobWarning: (
    <symbol id="jobWarningIcon" viewBox="0 0 20 16">
      <path
        fill="currentColor"
        d="M10 4.833c.46 0 .833.355.833.792v3.167c0 .437-.373.791-.833.791-.46 0-.833-.354-.833-.791V5.625c0-.437.373-.792.833-.792ZM10 11.167c-.46 0-.833.354-.833.791 0 .438.373.792.833.792h.008c.46 0 .834-.354.834-.792 0-.437-.373-.791-.834-.791H10Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.773.307C9.148.107 9.57.002 10 .002c.43 0 .852.105 1.226.305.375.2.689.49.911.838l.003.004 7.058 11.194.007.011c.218.36.334.766.335 1.181.001.415-.112.823-.328 1.183-.216.36-.528.66-.904.87a2.6 2.6 0 0 1-1.24.329H2.931a2.6 2.6 0 0 1-1.24-.328 2.44 2.44 0 0 1-.904-.871 2.284 2.284 0 0 1-.328-1.183c.001-.415.117-.822.335-1.18l.007-.012L7.86 1.15l.715.407-.713-.41c.223-.35.537-.638.911-.839Zm.515 1.658L2.236 13.15a.762.762 0 0 0 0 .784c.072.12.176.22.301.29.125.07.266.107.41.11h14.106a.866.866 0 0 0 .41-.11c.125-.07.229-.17.301-.29a.762.762 0 0 0 0-.784L10.713 1.966l-.002-.001a.816.816 0 0 0-.302-.278.868.868 0 0 0-.818 0 .816.816 0 0 0-.303.278Z"
        clipRule="evenodd"
      />
    </symbol>
  ),
  jobRejected: (
    <symbol id="jobRejectedIcon" viewBox="0 0 20 18">
      <path
        fill="currentColor"
        d="M10 5.042c.46 0 .833.354.833.791V9c0 .437-.373.792-.833.792-.46 0-.834-.355-.834-.792V5.833c0-.437.373-.791.834-.791ZM10 11.375c-.46 0-.834.354-.834.792 0 .437.373.791.834.791h.008c.46 0 .833-.354.833-.791 0-.438-.373-.792-.833-.792H10Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.833 9C.833 4.19 4.937.292 10 .292c5.062 0 9.166 3.899 9.166 8.708 0 4.81-4.104 8.708-9.166 8.708C4.937 17.708.833 13.81.833 9ZM10 1.875c-4.142 0-7.5 3.19-7.5 7.125s3.358 7.125 7.5 7.125c4.142 0 7.5-3.19 7.5-7.125S14.142 1.875 10 1.875Z"
        clipRule="evenodd"
      />
    </symbol>
  ),
  issue: (
    <symbol id="issueIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <path
        fill="currentColor"
        d="M11 12.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm0-12a10 10 0 0 0-10 10 9.89 9.89 0 0 0 2.26 6.33l-2 2a1 1 0 0 0-.21 1.09 1 1 0 0 0 .95.58h9a10 10 0 0 0 0-20Zm0 18H4.41l.93-.93a1 1 0 0 0 .3-.71 1 1 0 0 0-.3-.7A8 8 0 1 1 11 18.5Zm0-12a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3a1 1 0 0 0-1-1Z"
      />
    </symbol>
  ),
};

const jobStageStatusIcons = {
  toSchedule: (
    <symbol id="toScheduleIcon" viewBox="0 0 16 17">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.2a7.3 7.3 0 1 0 0 14.6A7.3 7.3 0 0 0 8 1.2ZM2 8.5a6 6 0 1 1 12 0 6 6 0 0 1-12 0Z"
        fill="currentColor"
      />
    </symbol>
  ),
  error: (
    <symbol id="errorIcon" viewBox="0 0 16 15">
      <path
        d="M8 4.2c.4 0 .7.3.7.6v2.7a.7.7 0 0 1-1.4 0V4.8c0-.3.3-.6.7-.6ZM8 9.5a.7.7 0 1 0 0 1.3.7.7 0 0 0 0-1.3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.7 7.5a7.3 7.3 0 1 1 14.6 0 7.3 7.3 0 0 1-14.6 0Zm7.3-6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z"
        fill="currentColor"
      />
    </symbol>
  ),
  warning: (
    <symbol id="warningIcon" viewBox="0 0 17 17">
      <path
        d="M8.5 5.7c.4 0 .7.3.7.7v2.8a.7.7 0 1 1-1.4 0V6.4c0-.4.3-.7.7-.7ZM8.5 11.3a.7.7 0 0 0 0 1.5.7.7 0 1 0 0-1.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1.6a2.1 2.1 0 0 1 2.8.8l6 10a2.1 2.1 0 0 1-1.8 3.2h-12a2.1 2.1 0 0 1-1.8-3.2l6-10 .6.3-.6-.3c.2-.3.4-.6.8-.8Zm.4 1.5-6 10a.7.7 0 0 0 .6 1h12a.7.7 0 0 0 .6-1l-6-10a.7.7 0 0 0-1.2 0Z"
        fill="currentColor"
      />
    </symbol>
  ),
  completed: (
    <symbol id="completedIcon" viewBox="0 0 14 15">
      <path
        d="M8.8 5.4 6 8.2l-1.1-1a.7.7 0 1 0-1 .9l1.6 1.5a.7.7 0 0 0 1 0l3.3-3.3a.7.7 0 1 0-1-1ZM7 .8a6.7 6.7 0 1 0 0 13.4A6.7 6.7 0 0 0 7 .8Zm0 12A5.3 5.3 0 1 1 7 2.2a5.3 5.3 0 0 1 0 10.6Z"
        fill="currentColor"
      />
    </symbol>
  ),
  scheduled: (
    <symbol id="scheduledIcon" viewBox="0 0 14 14">
      <path
        d="M2 13.9c-.4 0-.7-.1-1-.4-.3-.2-.4-.5-.4-.9V3c0-.4.1-.7.4-1 .3-.2.6-.3 1-.3h1v-1h1.3v1h5.4v-1H11v1h1c.4 0 .7.1 1 .4.3.2.4.5.4.9v9.6c0 .4-.1.7-.4 1-.3.2-.6.3-1 .3H2Zm0-1.3h10V6H2v6.7ZM2 5h10V3H2v2Zm5 3.7c-.2 0-.4 0-.5-.2a.6.6 0 0 1-.2-.4c0-.2 0-.4.2-.5.1-.2.3-.2.5-.2s.4 0 .5.2c.2 0 .2.3.2.4 0 .2 0 .4-.2.5-.1.2-.3.2-.5.2Zm-2.7 0c-.2 0-.4 0-.6-.2a.6.6 0 0 1-.2-.4c0-.2 0-.4.2-.5.2-.2.3-.2.6-.2.2 0 .3 0 .5.2l.2.4c0 .2 0 .4-.2.5-.2.2-.3.2-.5.2Zm5.4 0c-.2 0-.3 0-.5-.2A.6.6 0 0 1 9 8c0-.2 0-.4.2-.5.2-.2.3-.2.5-.2s.4 0 .6.2l.2.4-.2.5c-.2.2-.4.2-.6.2ZM7 11.2l-.5-.2a.6.6 0 0 1-.2-.5c0-.2 0-.4.2-.5l.5-.2c.2 0 .4 0 .5.2.2.1.2.3.2.5s0 .3-.2.5l-.5.2Zm-2.7 0-.6-.2a.6.6 0 0 1-.2-.5c0-.2 0-.4.2-.5l.6-.2c.2 0 .3 0 .5.2l.2.5c0 .2 0 .3-.2.5l-.5.2Zm5.4 0-.5-.2a.6.6 0 0 1-.2-.5c0-.2 0-.4.2-.5l.5-.2c.2 0 .4 0 .6.2l.2.5-.2.5-.6.2Z"
        fill="currentColor"
      />
    </symbol>
  ),
};

const hotSeatIcons = {
  engineerInspection: (
    <symbol id="engineerInspectionIcon" viewBox="0 0 17 18">
      <path
        d="M15.2 14.6 12.7 12a6.6 6.6 0 0 0-.7-8.8 6 6 0 0 0-8.3.3 6.6 6.6 0 0 0-.2 8.8 5.9 5.9 0 0 0 8.3.7l2.5 2.6a.7.7 0 0 0 .4.2.6.6 0 0 0 .5-.2l.2-.5c0-.2 0-.3-.2-.5ZM8 13c-1 0-1.9-.3-2.7-.8a5 5 0 0 1-1.7-2.3 5.2 5.2 0 0 1 1-5.4c.7-.7 1.5-1.2 2.4-1.4 1-.2 2 0 2.8.3a5.2 5.2 0 0 1 1.5 8.1c-.9 1-2 1.5-3.3 1.5Z"
        fill="currentColor"
      />
    </symbol>
  ),
  pump: (
    <symbol id="pumpIcon" viewBox="0 0 20 18">
      <path
        d="M2.7 14.6c.3-1 1-1.5 2-1.5.9 0 1.7.6 2 1.5h2.2V7h4.3l1.4 3.8 2.8.7v4.6h-2.2c-.3.9-1 1.5-2 1.5s-1.7-.6-2-1.5H6.7c-.3.9-1.1 1.5-2 1.5-1 0-1.7-.6-2-1.5H.8v-1.5h1.9ZM13.2 16c.4 0 .7-.4.7-.8s-.3-.7-.7-.7c-.4 0-.7.3-.7.7 0 .4.3.8.7.8Zm-2.9-1.5h.9c.3-1 1-1.5 2-1.5s1.7.6 2 1.5h.8v-1.9l-1.6-.4h-4v2.3Zm0-3.8h2.8l-.9-2.3h-1.9v2.3ZM4.7 16c.4 0 .7-.4.7-.8s-.3-.7-.7-.7c-.4 0-.8.3-.8.7 0 .4.4.8.8.8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6.4 10.2 2 4.4H1.5l2-4.2c.2-.4.6-1.1 1.5-1.1s1.3.6 1.4.9Zm-1.4 2c.4 0 .7-.2.7-.7 0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7 0 .5.3.8.7.8Z"
        fill="currentColor"
      />
      <path
        d="M13.3 1a1 1 0 0 0-.9 0c-.3 0-.5.3-.4.6L13.3 5 9.8 3.2a1 1 0 0 0-.6 0 1 1 0 0 0-.6.2L4.3 8.7c0 .1-.3.4 0 .4h1.3l3.8-4.6 4.7 2.4h.9c.3-.1.5-.4.4-.7L14 2.8l3.8 2h.5l.5-.1c.2-.1.3-.3.3-.4 0-.2-.1-.4-.3-.4l-5.6-3Z"
        fill="currentColor"
      />
    </symbol>
  ),
  finalYards: (
    <symbol id="finalYardsIcon" viewBox="0 0 18 18">
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.4a2 2 0 0 1 2 0l4.8 3c.3 0 .5.3.7.5a.7.7 0 0 1 .2.3l.2 1V12c0 .4-.1.8-.3 1.1-.2.4-.4.6-.8.8L10 17a2 2 0 0 1-.7.2.7.7 0 0 1-.7 0A2 2 0 0 1 8 17l-4.8-3c-.4-.2-.6-.4-.8-.8-.2-.3-.3-.7-.3-1V6.1c0-.3 0-.7.2-1a.8.8 0 0 1 .2-.3l.6-.6L5.4 3c.1-.2.2-.2.4-.3l2.2-1.3Zm6.2 11.3-4.6 2.7V9.5l4.9-3V12l-.1.4-.2.3ZM3.3 6.6l4.9 3v5.8l-4.6-2.7a.7.7 0 0 1-.2-.3l-.1-.4V6.6Zm5.2-4h.7l.1.1 4.4 2.6-4.9 3-4.8-3 4.5-2.6Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M.6.4h16.7v17.5H.6z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  concreteOrder: (
    <symbol id="concreteOrderIcon" viewBox="0 0 24 25">
      <path
        d="M18.4 16c-.3-1-1.2-1.6-2.2-1.6-1 0-1.8.6-2.2 1.6h-2.4V7.9H7l-1.6 4-3 .8v5h2.4c.3.9 1.1 1.5 2.2 1.5 1 0 1.8-.6 2.1-1.6h5c.3 1 1.1 1.6 2.1 1.6s1.9-.6 2.2-1.6h2.5V16h-2.5ZM7 17.6a.8.8 0 0 1-.8-.8c0-.4.3-.8.8-.8.4 0 .7.4.7.8s-.3.8-.7.8Zm3-1.6h-.9C8.8 15 8 14.4 7 14.4S5 15 4.8 16h-1v-2l1.8-.4H10V16Zm0-4H7l1-2.5h2v2.4Zm6.2 5.6a.8.8 0 0 1-.8-.8c0-.4.4-.8.8-.8s.8.4.8.8-.4.8-.8.8Z"
        fill="currentColor"
      />
      <path
        d="M17.2 6.5c-.4-.2-.9-.3-1.4-.3-1.9 0-3.4 1.7-3.4 3.7s1.5 3.7 3.4 3.7c.5 0 1-.1 1.4-.3l3.7-1.6V8l-3.7-1.6Zm2.1 4-2.7 1.3-.8.1c-1 0-1.9-.9-1.9-2s.9-2 2-2l.7.1 2.7 1.2v1.4Z"
        fill="currentColor"
      />
    </symbol>
  ),
};

const documentsIcons = {
  addDocument: (
    <symbol id="addDocumentIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
      <path
        fill="currentColor"
        d="M13 12.5h-.75v-.75a.75.75 0 1 0-1.5 0v.75H10a.75.75 0 1 0 0 1.5h.75v.75a.75.75 0 1 0 1.5 0V14H13a.75.75 0 1 0 0-1.5ZM7.75 14H2.5a.75.75 0 0 1-.75-.75V2.75A.75.75 0 0 1 2.5 2h3.75v2.25A2.25 2.25 0 0 0 8.5 6.5h2.25v2.25a.75.75 0 1 0 1.5 0V5.705a.982.982 0 0 0-.045-.202v-.068a.804.804 0 0 0-.143-.21l-4.5-4.5a.802.802 0 0 0-.21-.142.217.217 0 0 0-.075 0A.825.825 0 0 0 7.045.5H2.5A2.25 2.25 0 0 0 .25 2.75v10.5A2.25 2.25 0 0 0 2.5 15.5h5.25a.75.75 0 1 0 0-1.5Zm0-10.942L9.693 5H8.5a.75.75 0 0 1-.75-.75V3.058ZM4 5a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5H4Zm3.75 6H4a.75.75 0 1 0 0 1.5h3.75a.75.75 0 1 0 0-1.5Zm.75-3H4a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5Z"
      />
    </symbol>
  ),
  downloadDocument: (
    <symbol id="downloadDocumentIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
      <path
        fill="currentColor"
        d="M4.218 8.967a.75.75 0 0 0 0 1.066l2.25 2.25a.75.75 0 0 0 1.064 0l2.25-2.25a.753.753 0 1 0-1.065-1.066l-.967.976V1.25a.75.75 0 0 0-1.5 0v8.693l-.968-.976a.75.75 0 0 0-1.064 0ZM11.5 5.75H10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-.75.75h-9a.75.75 0 0 1-.75-.75V8a.75.75 0 0 1 .75-.75H4a.75.75 0 0 0 0-1.5H2.5A2.25 2.25 0 0 0 .25 8v5.25A2.25 2.25 0 0 0 2.5 15.5h9a2.25 2.25 0 0 0 2.25-2.25V8a2.25 2.25 0 0 0-2.25-2.25Z"
      />
    </symbol>
  ),
  uploadDocument: (
    <symbol id="uploadDocumentIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 15">
      <path
        fill="currentColor"
        d="M6.667 12.763H2a.667.667 0 0 1-.667-.667V2.763A.667.667 0 0 1 2 2.096h3.333v2a2 2 0 0 0 2 2h2V7.43a.667.667 0 0 0 1.334 0V5.39a.88.88 0 0 0-.04-.18v-.06a.714.714 0 0 0-.127-.186l-4-4a.713.713 0 0 0-.187-.127.213.213 0 0 0-.06 0 .587.587 0 0 0-.22-.073H2a2 2 0 0 0-2 2v9.333a2 2 0 0 0 2 2h4.667a.667.667 0 0 0 0-1.333Zm0-9.727 1.726 1.727h-1.06a.667.667 0 0 1-.666-.667v-1.06ZM3.333 4.763a.667.667 0 0 0 0 1.333H4a.667.667 0 0 0 0-1.333h-.667Zm4 2.666h-4a.667.667 0 1 0 0 1.334h4a.667.667 0 0 0 0-1.334Zm4.474 3.527-1.334-1.333a.666.666 0 0 0-.22-.14.667.667 0 0 0-.506 0 .666.666 0 0 0-.22.14l-1.334 1.333a.67.67 0 1 0 .947.947l.193-.2v1.727a.667.667 0 1 0 1.334 0v-1.727l.193.2a.666.666 0 0 0 .947 0 .668.668 0 0 0 0-.947ZM6 11.43a.667.667 0 0 0 0-1.334H3.333a.667.667 0 1 0 0 1.334H6Z"
      />
    </symbol>
  ),
};

const filesIcons = {
  plansFiles: (
    <symbol id="plansFilesIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17">
      <path
        fill="currentColor"
        d="M20 7.44a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.1a1.1 1.1 0 0 0-.31-.11H3a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7.44Zm-6-3.53 2.59 2.59H15a1 1 0 0 1-1-1V3.91Zm4 9.59a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h9v3a3 3 0 0 0 3 3h3v5Zm-13-3a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2H5Z"
      />
      <path fill="currentColor" d="M6 5.5v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 2 0Z" />
      <path fill="currentColor" d="M5 4.5h4a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
      <path
        fill="currentColor"
        d="M10 5.5v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 2 0ZM10 9.5v1a1 1 0 1 1-2 0v-1a1 1 0 0 1 2 0ZM16 10.5v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 2 0Z"
      />
    </symbol>
  ),
  documentsFiles: (
    <symbol id="documentsFilesIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21">
      <path
        fill="currentColor"
        d="M9.5 18.5h-6a1 1 0 0 1-1-1v-14a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v5a1 1 0 0 0 2 0V7.44a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19.29.29 0 0 0-.1 0A1.1 1.1 0 0 0 9.56.5H3.5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6a1 1 0 0 0 0-2Zm1-14.59 2.59 2.59H11.5a1 1 0 0 1-1-1V3.91Zm-5 8.59h6a1 1 0 0 0 0-2h-6a1 1 0 0 0 0 2Zm4 2h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2Zm-4-6h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2Zm13.71 6.29a1.002 1.002 0 0 0-1.42 0l-3.29 3.3-1.29-1.3a1.004 1.004 0 0 0-1.42 1.42l2 2a1.002 1.002 0 0 0 1.42 0l4-4a1.002 1.002 0 0 0 0-1.42Z"
      />
    </symbol>
  ),
  otherFiles: (
    <symbol id="otherFilesIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 21">
      <path
        fill="currentColor"
        d="M8.71 9.79a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-2 2a1.004 1.004 0 0 0 1.42 1.42l.29-.3v2.59a1 1 0 1 0 2 0v-2.59l.29.3a1 1 0 0 0 1.639-.325 1 1 0 0 0-.219-1.095l-2-2ZM16 7.44a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.1A1.1 1.1 0 0 0 9.06.5H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V7.44Zm-6-3.53 2.59 2.59H11a1 1 0 0 1-1-1V3.91Zm4 13.59a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-14a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v9Z"
      />
    </symbol>
  ),
};

export const Icons = {
  ...mapIcons,
  ...jobIcons,
  ...jobStageStatusIcons,
  ...hotSeatIcons,
  ...documentsIcons,
  ...filesIcons,
  trash: (
    <symbol id="trashIcon" viewBox="0 0 18 18">
      <path
        d="M7.5 7.5c.4 0 .8.3.8.8v4.4a.8.8 0 0 1-1.6 0V8.3c0-.5.4-.8.8-.8ZM11.3 12.8V8.2a.8.8 0 0 0-1.6 0v4.6a.8.8 0 0 0 1.6 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3 3.8V3A2.2 2.2 0 0 1 7.5.7h3A2.3 2.3 0 0 1 12.8 3v.8h3a.8.8 0 0 1 0 1.5H15V15a2.3 2.3 0 0 1-2.3 2.3H5.3A2.3 2.3 0 0 1 3 15V5.2h-.8a.8.8 0 0 1 0-1.5h3ZM7 2.5c.1-.2.3-.3.5-.3h3a.7.7 0 0 1 .8.8v.8H6.7V3c0-.2.1-.4.3-.5ZM4.5 5.3V15a.7.7 0 0 0 .8.8h7.5a.7.7 0 0 0 .7-.8V5.2h-9Z"
        fill="currentColor"
      />
    </symbol>
  ),
  edit: (
    <symbol id="editIcon" viewBox="0 0 18 18">
      <g clipPath="url(#a)" fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 .7c-.6 0-1.2.2-1.7.6l-7 7.2c-.2 0-.2.2-.3.3l-.7 3a.7.7 0 0 0 .9 1l3-.8.3-.2 7.2-7.1a2.3 2.3 0 0 0-1.7-4Zm-.6 1.7a.8.8 0 0 1 1.2 1.2l-7 7L7 11l.4-1.6 7-7Z"
        />
        <path d="M3 2.3A2.2 2.2 0 0 0 .7 4.5V15A2.3 2.3 0 0 0 3 17.3h10.5a2.3 2.3 0 0 0 2.3-2.3V9.7a.8.8 0 0 0-1.6 0V15a.7.7 0 0 1-.7.8H3a.7.7 0 0 1-.8-.8V4.5a.8.8 0 0 1 .8-.8h5.3a.8.8 0 0 0 0-1.5H3Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  repeat: (
    <symbol id="repeatIcon" viewBox="0 0 18 18">
      <g clipPath="url(#a)" fill="currentColor">
        <path d="M13.3.2a.7.7 0 1 0-1 1L13.8 3H5.2a3.8 3.8 0 0 0-3.7 3.8v1.5a.8.8 0 0 0 1.5 0V6.7a2.2 2.2 0 0 1 2.3-2.2h8.6l-1.7 1.7a.7.7 0 0 0 1 1l3-3c.4-.2.4-.7 0-1l-3-3ZM5.8 10.7c.3.3.3.8 0 1L4 13.6h8.7a2.3 2.3 0 0 0 2.2-2.3V9.8a.8.8 0 0 1 1.5 0v1.4a3.8 3.8 0 0 1-3.8 3.8H4.2l1.7 1.7a.7.7 0 1 1-1 1l-3-3a.7.7 0 0 1 0-1l3-3c.2-.3.7-.3 1 0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </symbol>
  ),
  priority: (
    <symbol id="priorityIcon" viewBox="0 0 14 8">
      <path d="M1.6 7.7.1 6.5 7 .9l6.9 5.6-1.5 1.2L7 3.3 1.6 7.7Z" fill="currentColor" />
    </symbol>
  ),
  arrow: (
    <symbol id="arrowIcon" viewBox="0 0 7 11">
      <path d="m1.3 0 5 5-5 5-.9-.9 4.1-4L.5.8l.8-.9Z" fill="currentColor" />
    </symbol>
  ),
  tooltip: (
    <symbol id="tooltipIcon" viewBox="0 0 18 18">
      <path
        fill="currentColor"
        d="M9 8.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V9A.75.75 0 0 1 9 8.25ZM9 5.25a.75.75 0 0 0 0 1.5h.008a.75.75 0 0 0 0-1.5H9Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.75 9a8.25 8.25 0 1 1 16.5 0A8.25 8.25 0 0 1 .75 9ZM9 2.25a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Z"
        clipRule="evenodd"
      />
    </symbol>
  ),
  copy: (
    <symbol id="copyIcon" viewBox="0 0 18 18">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <path
          fill="currentColor"
          d="M15.75 6.705a.982.982 0 0 0-.045-.202v-.068a.804.804 0 0 0-.143-.21l-4.5-4.5a.803.803 0 0 0-.21-.143.24.24 0 0 0-.067 0 .66.66 0 0 0-.248-.082H7.5a2.25 2.25 0 0 0-2.25 2.25v.75H4.5a2.25 2.25 0 0 0-2.25 2.25v7.5A2.25 2.25 0 0 0 4.5 16.5h6a2.25 2.25 0 0 0 2.25-2.25v-.75h.75a2.25 2.25 0 0 0 2.25-2.25V6.705Zm-4.5-2.647L13.193 6H12a.75.75 0 0 1-.75-.75V4.058Zm0 10.192a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-7.5A.75.75 0 0 1 4.5 6h.75v5.25A2.25 2.25 0 0 0 7.5 13.5h3.75v.75Zm3-3a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-7.5A.75.75 0 0 1 7.5 3h2.25v2.25A2.25 2.25 0 0 0 12 7.5h2.25v3.75Z"
        />
      </svg>
    </symbol>
  ),
  menu: (
    <symbol id="menuIcon" viewBox="0 0 4 12">
      <path
        d="M0.833252 1.91667C0.833252 1.27233 1.35559 0.75 1.99992 0.75C2.64425 0.75 3.16659 1.27233 3.16659 1.91667C3.16659 2.561 2.64425 3.08333 1.99992 3.08333C1.35559 3.08333 0.833252 2.561 0.833252 1.91667Z"
        fill="currentColor"
      />
      <path
        d="M0.833252 6C0.833252 5.35567 1.35559 4.83333 1.99992 4.83333C2.64425 4.83333 3.16659 5.35567 3.16659 6C3.16659 6.64433 2.64425 7.16667 1.99992 7.16667C1.35559 7.16667 0.833252 6.64433 0.833252 6Z"
        fill="currentColor"
      />
      <path
        d="M1.99992 8.91667C1.35559 8.91667 0.833252 9.439 0.833252 10.0833C0.833252 10.7277 1.35559 11.25 1.99992 11.25C2.64425 11.25 3.16659 10.7277 3.16659 10.0833C3.16659 9.439 2.64425 8.91667 1.99992 8.91667Z"
        fill="currentColor"
      />
    </symbol>
  ),
  expand: (
    <symbol id="expandIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8">
      <path
        fill="currentColor"
        d="M11 5.41 6.71 1.17a1 1 0 0 0-1.42 0L1.05 5.41a1 1 0 1 0 1.41 1.42L6 3.29l3.54 3.54a1 1 0 0 0 1.41 0A1 1 0 0 0 11 5.41Z"
      />
    </symbol>
  ),
  calendar: (
    <symbol id="calendarIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M13.25 2h-1.5v-.75a.75.75 0 1 0-1.5 0V2h-4.5v-.75a.75.75 0 0 0-1.5 0V2h-1.5A2.25 2.25 0 0 0 .5 4.25v9a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-9A2.25 2.25 0 0 0 13.25 2ZM14 13.25a.75.75 0 0 1-.75.75H2.75a.75.75 0 0 1-.75-.75V8h12v5.25Zm0-6.75H2V4.25a.75.75 0 0 1 .75-.75h1.5v.75a.75.75 0 0 0 1.5 0V3.5h4.5v.75a.75.75 0 1 0 1.5 0V3.5h1.5a.75.75 0 0 1 .75.75V6.5Z"
      />
    </symbol>
  ),
  calendarMulti: (
    <symbol id="calendarMultiIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17">
      <path
        fill="currentColor"
        d="M13.25 1.5h-.75V.75a.75.75 0 1 0-1.5 0v.75H5V.75a.75.75 0 0 0-1.5 0v.75h-.75A2.25 2.25 0 0 0 .5 3.75v10.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V3.75a2.25 2.25 0 0 0-2.25-2.25ZM14 14.25a.75.75 0 0 1-.75.75H2.75a.75.75 0 0 1-.75-.75V7.5h12v6.75ZM14 6H2V3.75A.75.75 0 0 1 2.75 3h.75v.75a.75.75 0 0 0 1.5 0V3h6v.75a.75.75 0 1 0 1.5 0V3h.75a.75.75 0 0 1 .75.75V6Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.5 9.75A.75.75 0 0 1 4.25 9h7.5a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1-.75-.75ZM3.5 12.75a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
    </symbol>
  ),
  daily: (
    <symbol id="dailyIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
      <path
        fill="currentColor"
        d="M5.5 12.75a2.012 2.012 0 0 1-1.48-.604 2.012 2.012 0 0 1-.603-1.48c0-.583.201-1.076.604-1.479A2.012 2.012 0 0 1 5.5 8.583c.583 0 1.076.202 1.48.604.402.403.603.896.603 1.48 0 .583-.201 1.076-.604 1.479a2.012 2.012 0 0 1-1.479.604Zm-3.333 4.583c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177V4c0-.458.163-.85.49-1.177.326-.327.718-.49 1.177-.49H3V.667h1.667v1.666h6.666V.667H13v1.666h.833c.459 0 .851.163 1.177.49.327.326.49.719.49 1.177v11.666c0 .459-.163.851-.49 1.178-.326.326-.718.49-1.177.49H2.167Zm0-1.666h11.666V7.332H2.167v8.333Zm0-10h11.666V4H2.167v1.667Z"
      />
    </symbol>
  ),
  addNote: (
    <symbol id="addNoteIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M10.25 5.75h-1.5v-1.5a.75.75 0 0 0-1.5 0v1.5h-1.5a.75.75 0 0 0 0 1.5h1.5v1.5a.75.75 0 0 0 1.5 0v-1.5h1.5a.75.75 0 1 0 0-1.5Zm3-5.25H2.75A2.25 2.25 0 0 0 .5 2.75v7.5a2.25 2.25 0 0 0 2.25 2.25h8.693l2.774 2.783a.75.75 0 0 0 1.155-.118.75.75 0 0 0 .128-.415v-12A2.25 2.25 0 0 0 13.25.5ZM14 12.943l-1.717-1.726A.75.75 0 0 0 11.75 11h-9a.75.75 0 0 1-.75-.75v-7.5A.75.75 0 0 1 2.75 2h10.5a.75.75 0 0 1 .75.75v10.193Z"
      />
    </symbol>
  ),
  editNote: (
    <symbol id="editNoteIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M11.75 4.25h-7.5a.75.75 0 0 0 0 1.5h7.5a.75.75 0 1 0 0-1.5Zm0 3h-7.5a.75.75 0 0 0 0 1.5h7.5a.75.75 0 1 0 0-1.5ZM13.25.5H2.75A2.25 2.25 0 0 0 .5 2.75v7.5a2.25 2.25 0 0 0 2.25 2.25h8.693l2.774 2.783a.75.75 0 0 0 1.155-.118.75.75 0 0 0 .128-.415v-12A2.25 2.25 0 0 0 13.25.5ZM14 12.943l-1.717-1.726A.75.75 0 0 0 11.75 11h-9a.75.75 0 0 1-.75-.75v-7.5A.75.75 0 0 1 2.75 2h10.5a.75.75 0 0 1 .75.75v10.193Z"
      />
    </symbol>
  ),
  note: (
    <symbol id="noteIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 11">
      <path
        fill="currentColor"
        d="M10.096 0H2.083C1.628 0 1.19.174.869.483c-.322.31-.503.73-.503 1.167v5.5c0 .438.18.857.503 1.167.322.309.759.483 1.214.483h6.634l2.117 2.04a.573.573 0 0 0 .407.16.497.497 0 0 0 .217-.044.568.568 0 0 0 .257-.201.536.536 0 0 0 .098-.305v-8.8c0-.438-.18-.857-.503-1.167A1.753 1.753 0 0 0 10.096 0Z"
      />
    </symbol>
  ),
  file: (
    <symbol id="fileIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14">
      <path
        fill="currentColor"
        d="M6.667 12.333H2a.667.667 0 0 1-.667-.666V2.333A.667.667 0 0 1 2 1.667h3.333v2a2 2 0 0 0 2 2h2V7a.667.667 0 0 0 1.334 0V4.96a.88.88 0 0 0-.04-.18v-.06a.713.713 0 0 0-.127-.187l-4-4a.713.713 0 0 0-.187-.126.213.213 0 0 0-.06 0 .587.587 0 0 0-.22-.074H2a2 2 0 0 0-2 2v9.334a2 2 0 0 0 2 2h4.667a.667.667 0 0 0 0-1.334Zm0-9.726 1.726 1.726h-1.06a.667.667 0 0 1-.666-.666v-1.06ZM3.333 4.333a.667.667 0 0 0 0 1.334H4a.667.667 0 1 0 0-1.334h-.667Zm4 2.667h-4a.667.667 0 0 0 0 1.333h4a.667.667 0 0 0 0-1.333Zm4.474 3.527-1.334-1.334a.666.666 0 0 0-.22-.14.667.667 0 0 0-.506 0 .667.667 0 0 0-.22.14l-1.334 1.334a.67.67 0 1 0 .947.946l.193-.2V13a.667.667 0 1 0 1.334 0v-1.727l.193.2a.67.67 0 1 0 .947-.946ZM6 11a.667.667 0 1 0 0-1.333H3.333a.667.667 0 1 0 0 1.333H6Z"
      />
    </symbol>
  ),
  reject: (
    <symbol id="rejectIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 22">
      <path
        fill="currentColor"
        d="M14.21 7.29a1 1 0 0 0-1.42 0l-2.29 2.3-2.29-2.3a1.004 1.004 0 1 0-1.42 1.42L9.09 11l-2.3 2.29a1 1 0 0 0 .325 1.639 1.001 1.001 0 0 0 1.095-.219l2.29-2.3 2.29 2.3a1 1 0 0 0 1.42 0 1.002 1.002 0 0 0 0-1.42L11.91 11l2.3-2.29a1.001 1.001 0 0 0 0-1.42Zm3.36-3.36A10 10 0 1 0 3.43 18.07 10 10 0 1 0 17.57 3.93Zm-1.41 12.73A8 8 0 1 1 18.5 11a7.95 7.95 0 0 1-2.34 5.66Z"
      />
    </symbol>
  ),
  pin: (
    <symbol id="pinIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16">
      <path
        fill="currentColor"
        d="M9.277 8.592a.75.75 0 0 0-.554 1.396c1.095.434 1.777 1.11 1.777 1.762C10.5 12.815 8.655 14 6 14c-2.655 0-4.5-1.185-4.5-2.25 0-.652.683-1.328 1.777-1.762a.75.75 0 1 0-.554-1.396C1.02 9.268 0 10.445 0 11.75c0 2.1 2.632 3.75 6 3.75 3.367 0 6-1.65 6-3.75 0-1.305-1.02-2.482-2.723-3.158ZM5.25 6.395v5.355a.75.75 0 1 0 1.5 0V6.395a3 3 0 1 0-1.5 0ZM6 2a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
      />
    </symbol>
  ),
  back: (
    <symbol id="backIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <path
        fill="currentColor"
        d="M11 5H3.41l3.3-3.29A1.004 1.004 0 0 0 5.29.29l-5 5a1 1 0 0 0-.21.33 1 1 0 0 0 0 .76 1 1 0 0 0 .21.33l5 5a1 1 0 0 0 1.639-.325 1 1 0 0 0-.22-1.095L3.41 7H11a1 1 0 1 0 0-2Z"
      />
    </symbol>
  ),
  markAsDone: (
    <symbol id="markAsDoneIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 12">
      <path
        fill="currentColor"
        d="M6.406 11.306A.999.999 0 0 1 5 11.3L.762 7.063A.999.999 0 0 1 2.175 5.65l3.55 3.55.687.687a.999.999 0 0 1-.006 1.42Zm5.65-.013a1 1 0 0 1-1.413 0L6.4 7.05a.999.999 0 0 1 1.412-1.412l2.83 2.83a1 1 0 0 0 1.415 0l7.78-7.78A.999.999 0 1 1 21.25 2.1l-9.193 9.193ZM12.05 5.65a.999.999 0 1 1-1.413-1.413L14.175.7a.999.999 0 1 1 1.412 1.413L12.05 5.65Z"
      />
    </symbol>
  ),
  questionMark: (
    <symbol id="questionMarkIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21">
      <path
        fill="currentColor"
        d="M11.237 7.236a1.833 1.833 0 0 0-2.04 1.198.917.917 0 0 1-1.73-.608 3.667 3.667 0 0 1 7.126 1.222c0 1.402-1.04 2.329-1.783 2.824a7.374 7.374 0 0 1-1.545.778l-.032.01-.01.004-.004.002h-.001s-.002 0-.291-.87l.29.87a.917.917 0 0 1-.581-1.739l.014-.005.068-.025a5.545 5.545 0 0 0 1.075-.55c.632-.422.967-.87.967-1.3v-.002a1.833 1.833 0 0 0-1.523-1.81ZM11 14.547a.917.917 0 0 0 0 1.833h.009a.917.917 0 1 0 0-1.833h-.01Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.917 10.88C.917 5.311 5.43.797 11 .797S21.083 5.31 21.083 10.88 16.57 20.963 11 20.963.917 16.45.917 10.88ZM11 2.63a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5Z"
        clipRule="evenodd"
      />
    </symbol>
  ),
  uploadFile: (
    <symbol id="uploadFileIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
      <path
        fill="currentColor"
        d="M16.42 4.22A7 7 0 0 0 3.06 6.11 4 4 0 0 0 4 14a1 1 0 1 0 0-2 2 2 0 1 1 0-4 1 1 0 0 0 1-1 5 5 0 0 1 9.73-1.61 1 1 0 0 0 .78.67 3 3 0 0 1 .24 5.84 1.002 1.002 0 0 0 .5 1.94 5 5 0 0 0 .17-9.62Zm-5.71 2.07a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-3 3a1.004 1.004 0 1 0 1.42 1.42L9 9.41V15a1 1 0 1 0 2 0V9.41l1.29 1.3a1.001 1.001 0 0 0 1.639-.325 1 1 0 0 0-.219-1.095l-3-3Z"
      />
    </symbol>
  ),

  clock: (
    <symbol id="clockIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16">
      <path
        fill="currentColor"
        d="M8.708.083a7.917 7.917 0 1 0 0 15.834 7.917 7.917 0 0 0 0-15.834Zm0 14.25a6.333 6.333 0 1 1 0-12.667 6.333 6.333 0 0 1 0 12.667Zm0-11.083a.792.792 0 0 0-.791.792V7.54l-1.663.958a.792.792 0 0 0 .396 1.48.792.792 0 0 0 .396-.103L9.104 8.69l.071-.071.127-.103a.68.68 0 0 0 .08-.127.704.704 0 0 0 .062-.135.514.514 0 0 0 .04-.158L9.5 8V4.042a.792.792 0 0 0-.792-.792Z"
      />
    </symbol>
  ),
  phone: (
    <symbol id="phoneIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M13.58 8.75c-.165 0-.338-.053-.502-.09a7.076 7.076 0 0 1-.983-.293 1.5 1.5 0 0 0-1.86.75l-.165.338a9.134 9.134 0 0 1-1.995-1.5 9.135 9.135 0 0 1-1.5-1.995l.315-.21a1.5 1.5 0 0 0 .75-1.86 7.749 7.749 0 0 1-.293-.983 5.564 5.564 0 0 1-.09-.51A2.25 2.25 0 0 0 5.007.53h-2.25a2.25 2.25 0 0 0-2.25 2.557 14.25 14.25 0 0 0 12.39 12.345h.285A2.25 2.25 0 0 0 15.24 14.1c.129-.29.195-.606.193-.924v-2.25A2.25 2.25 0 0 0 13.58 8.75Zm.375 4.5a.75.75 0 0 1-.543.721.795.795 0 0 1-.327.029A12.75 12.75 0 0 1 2.052 2.915.818.818 0 0 1 2.24 2.3a.75.75 0 0 1 .562-.255h2.25a.75.75 0 0 1 .75.592c.03.205.068.408.113.608.087.395.202.784.345 1.162l-1.05.488a.75.75 0 0 0-.368.997 10.867 10.867 0 0 0 5.25 5.25.75.75 0 0 0 .57 0 .752.752 0 0 0 .428-.39l.465-1.05c.388.14.783.254 1.185.345.2.045.402.083.607.113a.75.75 0 0 1 .593.75l.015 2.34Z"
      />
    </symbol>
  ),
  outgoingCall: (
    <symbol id="outgoingCallIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15">
      <path
        fill="currentColor"
        d="M13.58 8.25c-.165 0-.338-.052-.502-.09a7.076 7.076 0 0 1-.983-.292 1.5 1.5 0 0 0-1.86.75l-.165.337a9.134 9.134 0 0 1-1.995-1.5 9.135 9.135 0 0 1-1.5-1.995l.315-.21a1.5 1.5 0 0 0 .75-1.86 7.749 7.749 0 0 1-.293-.982 5.564 5.564 0 0 1-.09-.51A2.25 2.25 0 0 0 5.007.03h-2.25a2.25 2.25 0 0 0-2.25 2.558 14.25 14.25 0 0 0 12.39 12.345h.285a2.25 2.25 0 0 0 2.057-1.334c.129-.29.195-.606.193-.924v-2.25A2.25 2.25 0 0 0 13.58 8.25Zm.375 4.5a.75.75 0 0 1-.543.721.795.795 0 0 1-.327.029A12.75 12.75 0 0 1 2.052 2.415.818.818 0 0 1 2.24 1.8a.75.75 0 0 1 .562-.255h2.25a.75.75 0 0 1 .75.593c.03.205.068.407.113.607.087.395.202.784.345 1.163l-1.05.487a.75.75 0 0 0-.368.998 10.867 10.867 0 0 0 5.25 5.25.75.75 0 0 0 .57 0 .748.748 0 0 0 .428-.39l.465-1.05c.388.138.783.253 1.185.345.2.045.402.082.607.112a.75.75 0 0 1 .593.75l.015 2.34ZM15.395.51a.75.75 0 0 0-.405-.405.75.75 0 0 0-.285-.06h-3a.75.75 0 1 0 0 1.5h1.185L10.422 4.02a.75.75 0 0 0 0 1.058.75.75 0 0 0 1.058 0l2.475-2.468v1.185a.75.75 0 1 0 1.5 0v-3a.75.75 0 0 0-.06-.285Z"
      />
    </symbol>
  ),
  add: (
    <symbol id="addIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M8 .5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM8 14A6 6 0 1 1 8 2a6 6 0 0 1 0 12Zm3-6.75H8.75V5a.75.75 0 1 0-1.5 0v2.25H5a.75.75 0 0 0 0 1.5h2.25V11a.75.75 0 1 0 1.5 0V8.75H11a.75.75 0 1 0 0-1.5Z"
      />
    </symbol>
  ),
  mail: (
    <symbol id="mailIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12">
      <path
        fill="currentColor"
        d="M13.25 0H2.75A2.25 2.25 0 0 0 .5 2.25v7.5A2.25 2.25 0 0 0 2.75 12h10.5a2.25 2.25 0 0 0 2.25-2.25v-7.5A2.25 2.25 0 0 0 13.25 0ZM2.75 1.5h10.5a.75.75 0 0 1 .75.75L8 5.91 2 2.25a.75.75 0 0 1 .75-.75ZM14 9.75a.75.75 0 0 1-.75.75H2.75A.75.75 0 0 1 2 9.75V3.96l5.61 3.428a.75.75 0 0 0 .75 0L14 3.96v5.79Z"
      />
    </symbol>
  ),
  close: (
    <symbol id="closeIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <path
        fill="currentColor"
        d="m6.94 6 4.2-4.193a.67.67 0 1 0-.947-.947L6 5.06 1.807.86a.67.67 0 0 0-.947.947L5.06 6l-4.2 4.193a.667.667 0 0 0 .217 1.093.666.666 0 0 0 .73-.146L6 6.94l4.193 4.2a.666.666 0 0 0 .947 0 .667.667 0 0 0 0-.947L6.94 6Z"
      />
    </symbol>
  ),
  loupe: (
    <symbol id="loupeIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.333 12.333a5.975 5.975 0 0 0 3.745-1.312l2.45 2.45a.667.667 0 1 0 .943-.943l-2.45-2.45a6 6 0 1 0-4.688 2.255Zm0-10.666a4.667 4.667 0 1 0 3.238 8.027.674.674 0 0 1 .123-.123 4.667 4.667 0 0 0-3.361-7.904Z"
        clipRule="evenodd"
      />
    </symbol>
  ),
  footingLayout: (
    <symbol id="footingLayoutIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
      <rect width="100%" height="100%" fill="#346BF5" rx="7.5" />
      <path
        fill="#fff"
        d="M6.878 2.758V12H4.65V2.758h2.228Zm3.593 3.846v1.714H6.243V6.604h4.228Zm.361-3.846v1.72H6.243v-1.72h4.59Z"
      />
    </symbol>
  ),
  wallsLayout: (
    <symbol id="wallsLayoutIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
      <rect width="100%" height="100%" fill="#F89215" rx="7.5" />
      <path
        fill="#fff"
        d="m5.068 10.646 1.7-7.177h.949l.217 1.195L6.123 12h-1.02l-.035-1.354ZM4.195 3.47l1.407 7.178L5.484 12H4.348L2.455 3.469h1.74Zm5.233 7.148 1.388-7.148h1.74L10.67 12H9.533l-.105-1.383ZM8.25 3.47l1.71 7.207L9.915 12h-1.02L7.073 4.658l.235-1.19h.943Z"
      />
    </symbol>
  ),
  visible: (
    <symbol id="visibleIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 15">
      <path
        fill="currentColor"
        d="M11 12c1.25 0 2.313-.438 3.188-1.313C15.062 9.813 15.5 8.75 15.5 7.5c0-1.25-.438-2.313-1.313-3.188C13.313 3.438 12.25 3 11 3c-1.25 0-2.313.438-3.188 1.313S6.5 6.25 6.5 7.5c0 1.25.438 2.313 1.313 3.188C8.688 11.562 9.75 12 11 12Zm0-1.8c-.75 0-1.387-.262-1.912-.787A2.604 2.604 0 0 1 8.3 7.5c0-.75.262-1.388.787-1.912A2.604 2.604 0 0 1 11 4.8c.75 0 1.387.263 1.912.788.525.524.788 1.162.788 1.912s-.262 1.387-.787 1.912A2.604 2.604 0 0 1 11 10.2Zm0 4.8c-2.433 0-4.65-.68-6.65-2.037C2.35 11.604.9 9.783 0 7.5c.9-2.283 2.35-4.104 4.35-5.463C6.35.68 8.567 0 11 0c2.433 0 4.65.68 6.65 2.038 2 1.358 3.45 3.179 4.35 5.462-.9 2.283-2.35 4.104-4.35 5.463C15.65 14.32 13.433 15 11 15Zm0-2a9.544 9.544 0 0 0 5.188-1.488A9.773 9.773 0 0 0 19.8 7.5a9.773 9.773 0 0 0-3.613-4.013A9.545 9.545 0 0 0 11 2a9.545 9.545 0 0 0-5.188 1.487A9.773 9.773 0 0 0 2.2 7.5a9.773 9.773 0 0 0 3.612 4.012A9.544 9.544 0 0 0 11 13Z"
      />
    </symbol>
  ),
  invisible: (
    <symbol id="invisibleIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21">
      <path
        fill="currentColor"
        d="m15.1 11.3-1.45-1.45c.15-.783-.075-1.517-.675-2.2-.6-.683-1.375-.95-2.325-.8L9.2 5.4c.283-.133.57-.233.863-.3A4.2 4.2 0 0 1 11 5c1.25 0 2.313.437 3.188 1.312.874.875 1.312 1.938 1.312 3.188 0 .333-.033.646-.1.938-.067.291-.167.579-.3.862Zm3.2 3.15-1.45-1.4a10.951 10.951 0 0 0 1.688-1.588A8.898 8.898 0 0 0 19.8 9.5c-.833-1.683-2.03-3.02-3.588-4.013C14.654 4.496 12.917 4 11 4c-.483 0-.958.033-1.425.1a9.623 9.623 0 0 0-1.375.3L6.65 2.85A11.08 11.08 0 0 1 11 2c2.517 0 4.758.696 6.725 2.087C19.692 5.48 21.117 7.283 22 9.5a11.696 11.696 0 0 1-1.512 2.738A11.007 11.007 0 0 1 18.3 14.45Zm.5 6.15-4.2-4.15c-.583.183-1.17.32-1.762.413-.592.091-1.205.137-1.838.137-2.517 0-4.758-.696-6.725-2.088C2.308 13.521.883 11.717 0 9.5c.35-.883.792-1.704 1.325-2.463A11.514 11.514 0 0 1 3.15 5L.4 2.2 1.8.8l18.4 18.4-1.4 1.4ZM4.55 6.4c-.483.433-.925.908-1.325 1.425A9.015 9.015 0 0 0 2.2 9.5c.833 1.683 2.03 3.02 3.587 4.012C7.346 14.505 9.083 15 11 15c.333 0 .658-.02.975-.063.317-.041.642-.087.975-.137l-.9-.95c-.183.05-.358.088-.525.113A3.539 3.539 0 0 1 11 14c-1.25 0-2.313-.438-3.188-1.313C6.938 11.813 6.5 10.75 6.5 9.5c0-.183.013-.358.037-.525.026-.167.063-.342.113-.525L4.55 6.4Z"
      />
    </symbol>
  ),
  notifications: (
    <symbol id="notificationsIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
      <path
        fill="currentColor"
        d="M6 28.5v-3h3V15c0-2 .6-4 1.9-5.5a8.7 8.7 0 0 1 4.8-3.2v-1c0-.7.3-1.2.7-1.6.4-.5 1-.7 1.6-.7.6 0 1.2.2 1.6.7.4.4.6 1 .6 1.5v1.1c2 .5 3.7 1.6 5 3.2A8.8 8.8 0 0 1 27 15v10.5h3v3H6ZM18 33c-.8 0-1.5-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1h6c0 .8-.3 1.5-.9 2.1-.6.6-1.3.9-2.1.9Zm-6-7.5h12V15c0-1.7-.6-3-1.8-4.2A5.8 5.8 0 0 0 18 9c-1.6 0-3 .6-4.2 1.8A5.8 5.8 0 0 0 12 15v10.5Z"
      />
    </symbol>
  ),
  archive: (
    <symbol id="archiveIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        fill="currentColor"
        d="m18.75 28.387-6.375-6.375 2.1-2.1 4.275 4.276 8.475-8.475 2.1 2.1L18.75 28.387ZM27 13.5h-3V6h-3v4.5H6V6H3v21h9v3H3a2.889 2.889 0 0 1-2.119-.881A2.889 2.889 0 0 1 0 27V6c0-.825.294-1.531.881-2.119A2.889 2.889 0 0 1 3 3h6.262c.275-.875.813-1.594 1.613-2.156A4.467 4.467 0 0 1 13.5 0c1 0 1.894.281 2.681.844.788.562 1.319 1.281 1.594 2.156H24c.825 0 1.531.294 2.119.881C26.706 4.47 27 5.175 27 6v7.5ZM13.5 6c.425 0 .781-.144 1.069-.431.287-.288.431-.644.431-1.069 0-.425-.144-.781-.431-1.069A1.451 1.451 0 0 0 13.5 3c-.425 0-.781.144-1.069.431A1.451 1.451 0 0 0 12 4.5c0 .425.144.781.431 1.069.288.287.644.431 1.069.431Z"
      />
    </symbol>
  ),
  jobStageMenu: (
    <symbol id="jobStageMenuIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M7 11h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 0 0 0-2h-2V7a1 1 0 0 0-2 0v2H7a1 1 0 0 0 0 2ZM19 0H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1Zm-1 18H2V2h16v16Z"
      />
    </symbol>
  ),
  taskRemove: (
    <symbol id="taskRemoveIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 17">
      <path
        fillRule="evenodd"
        d="M3.561 3.654V2.96A2.077 2.077 0 0 1 5.638.885h2.77a2.077 2.077 0 0 1 2.076 2.076v.693h2.77a.692.692 0 0 1 0 1.384h-.693v9a2.077 2.077 0 0 1-2.077 2.077H3.561a2.077 2.077 0 0 1-2.077-2.077v-9H.792a.692.692 0 0 1 0-1.384h2.77ZM5.15 2.472a.692.692 0 0 1 .49-.203h2.768a.692.692 0 0 1 .693.692v.693H4.946V2.96c0-.183.073-.36.203-.49Zm-2.28 2.566v9a.692.692 0 0 0 .692.693h6.923a.693.693 0 0 0 .693-.693v-9H2.869Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M9.362 7.168a.691.691 0 0 1 .377.377.691.691 0 0 1-.152.756l-1.59 1.584 1.59 1.583a.691.691 0 0 1-.225 1.133.691.691 0 0 1-.756-.152l-1.583-1.59-1.583 1.59a.691.691 0 0 1-1.133-.225.691.691 0 0 1 .15-.756l1.591-1.583L4.458 8.3a.694.694 0 0 1 .982-.981l1.583 1.59 1.583-1.59a.691.691 0 0 1 .756-.152Z"
      />
    </symbol>
  ),
  revert: (
    <symbol id="revertIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M19 9a1 1 0 0 0-1 1 8.05 8.05 0 1 1-2.22-5.5h-2.4a1 1 0 1 0 0 2h4.53a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v1.77A10 10 0 1 0 20 10a1 1 0 0 0-1-1Z"
      />
    </symbol>
  ),
  missingTask: (
    <symbol id="missingTaskIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
      <path
        fill="currentColor"
        d="M7.767 12.85a.633.633 0 0 0-.075-.15 1.313 1.313 0 0 0-.1-.125.833.833 0 0 0-.909-.175.832.832 0 0 0-.275.175c-.036.04-.069.081-.1.125a.632.632 0 0 0-.075.15.533.533 0 0 0-.05.15 1.248 1.248 0 0 0 0 .167.7.7 0 0 0 .067.316.958.958 0 0 0 .175.275c.153.152.36.239.575.242.11.003.218-.02.317-.067a.75.75 0 0 0 .45-.45.7.7 0 0 0 .066-.316 1.248 1.248 0 0 0 0-.167.532.532 0 0 0-.066-.15Zm5.9-6.4a1.097 1.097 0 0 0-.05-.225V6.15a.893.893 0 0 0-.159-.233l-5-5a.892.892 0 0 0-.233-.159H8.15a.733.733 0 0 0-.275-.091H2.833a2.5 2.5 0 0 0-2.5 2.5v11.666a2.5 2.5 0 0 0 2.5 2.5h8.334a2.5 2.5 0 0 0 2.5-2.5V6.45Zm-5-2.942 2.158 2.159H9.5a.833.833 0 0 1-.833-.834V3.508ZM12 14.833a.833.833 0 0 1-.833.834H2.833A.833.833 0 0 1 2 14.833V3.167a.833.833 0 0 1 .833-.834H7v2.5a2.5 2.5 0 0 0 2.5 2.5H12v7.5ZM7 8.167A.833.833 0 0 0 6.167 9v1.667a.833.833 0 0 0 1.666 0V9A.833.833 0 0 0 7 8.167Z"
      />
    </symbol>
  ),
  link: (
    <symbol id="linkIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <path
        fill="currentColor"
        d="M1.917 11.25c-.321 0-.596-.114-.824-.343a1.123 1.123 0 0 1-.343-.824V1.917c0-.321.114-.596.343-.824.228-.229.503-.343.824-.343H6v1.167H1.917v8.166h8.166V6h1.167v4.083c0 .321-.114.596-.343.824a1.123 1.123 0 0 1-.824.343H1.917Zm2.741-3.092-.816-.816 5.425-5.425h-2.1V.75h4.083v4.083h-1.167v-2.1L4.658 8.158Z"
      />
    </symbol>
  ),
  plus: (
    <symbol id="plusIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16">
      <path
        fill="currentColor"
        d="M15.935 7h-6V1a1 1 0 1 0-2 0v6h-6a1 1 0 0 0 0 2h6v6a1 1 0 1 0 2 0V9h6a1 1 0 1 0 0-2Z"
      />
    </symbol>
  ),
  company: (
    <symbol id="companyIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 17">
      <path
        fill="currentColor"
        d="M0 17V0h13v17H7.429v-3.778H5.57V17H0Zm1.857-1.889h1.857v-3.778h5.572v3.778h1.857V1.89H1.857V15.11Zm1.857-5.667h1.857V7.556H3.714v1.888Zm0-3.777h1.857v-1.89H3.714v1.89ZM7.43 9.444h1.857V7.556H7.429v1.888Zm0-3.777h1.857v-1.89H7.429v1.89Z"
      />
    </symbol>
  ),
  gear: (
    <symbol id="gearIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fill="currentColor"
        d="M12.267 7.44a.667.667 0 0 1 0-.88l.853-.96a.667.667 0 0 0 .08-.78l-1.333-2.307a.666.666 0 0 0-.714-.32L9.9 2.447a.667.667 0 0 1-.767-.44L8.727.787a.667.667 0 0 0-.634-.454H5.427a.667.667 0 0 0-.667.454l-.373 1.22a.667.667 0 0 1-.767.44l-1.287-.254a.667.667 0 0 0-.666.32L.333 4.82A.667.667 0 0 0 .4 5.6l.847.96a.667.667 0 0 1 0 .88L.4 8.4a.667.667 0 0 0-.067.78l1.334 2.307a.667.667 0 0 0 .713.32l1.253-.254a.667.667 0 0 1 .767.44l.407 1.22a.667.667 0 0 0 .666.454H8.14a.667.667 0 0 0 .633-.454l.407-1.22a.667.667 0 0 1 .767-.44l1.253.254a.667.667 0 0 0 .713-.32l1.334-2.307a.666.666 0 0 0-.08-.78l-.9-.96Zm-.994.893.534.6-.854 1.48-.786-.16a2 2 0 0 0-2.3 1.334l-.254.746H5.907l-.24-.76a2 2 0 0 0-2.3-1.333l-.787.16-.867-1.473.534-.6a2 2 0 0 0 0-2.667l-.534-.6.854-1.467.786.16a2 2 0 0 0 2.3-1.333l.254-.753h1.706l.254.76a2 2 0 0 0 2.3 1.333l.786-.16.854 1.48-.534.6a2 2 0 0 0 0 2.653Zm-4.513-4a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.334Zm0 4a1.333 1.333 0 1 1 0-2.666 1.333 1.333 0 0 1 0 2.666Z"
      />
    </symbol>
  ),
  pickUp: (
    <symbol id="pickUpIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20">
      <path
        fill="currentColor"
        d="M1.08 19.748h14a1 1 0 0 0 0-2h-14a1 1 0 0 0 0 2Zm7.71-18.71a1 1 0 0 0-.33-.21.94.94 0 0 0-.76 0 1 1 0 0 0-.33.21l-4 4a1.004 1.004 0 1 0 1.42 1.42l2.29-2.3v11.59a1 1 0 1 0 2 0V4.158l2.29 2.3a1.005 1.005 0 0 0 1.42-1.42l-4-4Z"
      />
    </symbol>
  ),
  dropOff: (
    <symbol id="dropOffIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21">
      <path
        fill="currentColor"
        d="M15.08 18.748h-14a1 1 0 1 0 0 2h14a1 1 0 0 0 0-2Zm-7.71-2.29a1 1 0 0 0 .33.21.94.94 0 0 0 .76 0 1 1 0 0 0 .33-.21l4-4a1.004 1.004 0 1 0-1.42-1.42l-2.29 2.3V1.748a1 1 0 0 0-2 0v11.59l-2.29-2.3a1.004 1.004 0 1 0-1.42 1.42l4 4Z"
      />
    </symbol>
  ),
  exclamationMark: (
    <symbol id="exclamationMarkIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 14">
      <path
        fill="currentColor"
        d="M1.5.625a1.24 1.24 0 0 0-1.24 1.24v6.02a1.24 1.24 0 0 0 2.48 0v-6.02A1.24 1.24 0 0 0 1.5.625ZM1.5 13.375a1.417 1.417 0 1 0 0-2.833 1.417 1.417 0 0 0 0 2.833Z"
      />
    </symbol>
  ),
  questionary: (
    <symbol id="questionaryIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21">
      <path
        fill="currentColor"
        d="M16.5 7.44a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19.32.32 0 0 0-.09 0A.88.88 0 0 0 9.55.5H3.5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V7.44Zm-6-3.53 2.59 2.59H11.5a1 1 0 0 1-1-1V3.91Zm4 13.59a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-14a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v9Zm-3.71-6.71-3.29 3.3-1.29-1.3a1.004 1.004 0 1 0-1.42 1.42l2 2a1 1 0 0 0 1.42 0l4-4a1.004 1.004 0 1 0-1.42-1.42Z"
      />
    </symbol>
  ),
  integration: (
    <symbol id="integrationIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 9">
      <path
        fill="currentColor"
        d="M8.556 8.889H5c-1.23 0-2.278-.433-3.144-1.3-.867-.867-1.3-1.915-1.3-3.144 0-1.23.433-2.278 1.3-3.145C2.722.433 3.77 0 5 0h3.556v1.778H5c-.74 0-1.37.26-1.889.778a2.572 2.572 0 0 0-.778 1.889c0 .74.26 1.37.778 1.888A2.572 2.572 0 0 0 5 7.111h3.556V8.89ZM5.889 5.333V3.556H13v1.777H5.889Zm4.444 3.556V7.11h3.556c.74 0 1.37-.26 1.889-.778a2.572 2.572 0 0 0 .778-1.888c0-.741-.26-1.37-.778-1.89a2.572 2.572 0 0 0-1.89-.777h-3.555V0h3.556c1.23 0 2.278.433 3.144 1.3.867.867 1.3 1.915 1.3 3.145s-.433 2.277-1.3 3.144c-.866.867-1.914 1.3-3.144 1.3h-3.556Z"
      />
    </symbol>
  ),
  builder: (
    <symbol id="builderIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16">
      <path
        fill="currentColor"
        d="M.5 16V5.333L7.424 0l5.305 3.719C12 4 11.338 4.326 11 4.5c-.507.261-.466.19-.77.375l-2.705-1.86L2.729 6.72V13.5h3.323c-.024.264 0 1.531.006.95a1.386 1.386 0 0 0-.006.13V16H.5Zm6.795 0v-1.757c0-.316.077-.616.23-.898.152-.283.365-.51.637-.682a8.676 8.676 0 0 1 2.037-.884 8.01 8.01 0 0 1 4.396-.006c.714.203 1.393.5 2.038.89.272.164.485.39.638.676.153.286.229.586.229.9V16H7.295ZM9.5 14.5l6 .018v-.617c-.541-.292-.672-.39-1.248-.562-.577-.171-1.258-.245-1.86-.245-.598 0-1.087.072-1.658.25-.57.18-.693.267-1.234.56v.595Zm2.902-3.773a2.5 2.5 0 0 1-1.854-.782 2.61 2.61 0 0 1-.766-1.898c0-.744.254-1.379.761-1.903a2.478 2.478 0 0 1 1.85-.787 2.5 2.5 0 0 1 1.854.782 2.61 2.61 0 0 1 .766 1.898c0 .745-.254 1.379-.761 1.903a2.478 2.478 0 0 1-1.85.787Zm-.003-1.48c.333 0 .611-.116.835-.347.225-.231.337-.518.337-.86 0-.34-.113-.627-.338-.857a1.123 1.123 0 0 0-.837-.345c-.332 0-.61.116-.835.347a1.185 1.185 0 0 0-.336.858c0 .342.112.628.337.858.225.23.504.345.837.345Z"
      />
    </symbol>
  ),
};

export const SvgSources = () => (
  <div style={{ display: 'none' }}>
    <svg
      display="none"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet">
      {Object.entries(Icons).map(([k, v], index) => (
        <React.Fragment key={`${k}_${index}`}>{v}</React.Fragment>
      ))}
    </svg>
  </div>
);
