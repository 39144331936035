import { useSchedulerContext } from '@contexts/scheduler';
import { SchedulerGrid } from './grid';
import { SchedulerProps } from './types';
import { TouchEvent, useEffect } from 'react';
import { getFirstHour, getHours, setDatesForCurrentDay } from '@contexts/scheduler/helpers';
import { getDividedHours } from './grid/helpers';
import { useScheduler } from '@hooks/scheduler';
import { parseISO } from 'date-fns';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { getSegmentFromPoint } from '@hooks/scheduler/dnd/helpers';

export const Scheduler = ({
  date,
  foremen,
  withoutTeam,
  modal,
  inventory,
  schedule,
  activeId,
}: SchedulerProps) => {
  const {
    date: contextDate,
    data: { raw },
    active,
    setState,
  } = useSchedulerContext();

  const { getSchedule, setSchedule } = useScheduler();

  useEffect(() => {
    if (!foremen || !withoutTeam) return;
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        fresh: false,
      },
      data: {
        ...prev.data,
        raw: { foremen, withoutTeam },
      },
    }));
  }, [foremen, withoutTeam, setState]);

  useEffect(() => {
    if (!date) return;
    setState((prev) => ({ ...prev, date: getFirstHour(date) }));
  }, [date, setState]);

  useEffect(() => {
    if (!contextDate) return;
    setState((prev) => ({ ...prev, hours: getDividedHours(getHours(contextDate)) }));
  }, [contextDate, setState]);

  useEffect(() => {
    if (!modal) return;
    setState((prev) => ({ ...prev, modal }));
  }, [modal, setState]);

  useEffect(() => {
    if (!inventory) return;
    setState((prev) => ({ ...prev, inventory }));
  }, [inventory, setState]);

  useEffect(() => {
    const storedSchedule = active.schedule.id
      ? active.schedule
      : getSchedule(schedule?.id, false) || schedule;
    if (!storedSchedule || !storedSchedule.startsAt || !storedSchedule.endsAt || active?.fresh)
      return;
    if (activeId && storedSchedule?.id !== activeId) return;
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        fresh: false,
        schedule: {
          ...storedSchedule,
          task: schedule?.task || storedSchedule.task,
          ...setDatesForCurrentDay(contextDate, storedSchedule.startsAt, storedSchedule.endsAt),
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule, raw, contextDate, setState, activeId]);

  useEffect(() => {
    if (!active.schedule?.id || active?.fresh) return;
    const { startsAt, endsAt } = setDatesForCurrentDay(
      contextDate,
      active.schedule.startsAt,
      active.schedule.endsAt,
    );
    getSchedule(active.schedule.id);
    setSchedule(
      { ...active.schedule, task: schedule?.task || active.schedule.task, startsAt, endsAt },
      typeof active.newValues.foremanId === 'string'
        ? active.newValues.foremanId
        : active.schedule.foreman?.id || '',
    );
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        newValues: {
          ...prev.active.newValues,
          startsAt: parseISO(startsAt),
          endsAt: parseISO(endsAt),
        },
        schedule: {
          ...prev.active.schedule,
          foreman: { id: prev.active.newValues.foremanId || prev.active.schedule.foreman.id },
          task: schedule?.task || active.schedule.task,
        },
        fresh: true,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active.schedule]);

  const mouseSensor = useSensor(MouseSensor, {
    onActivation: ({ event }) => {
      const { clientX, clientY } = event as unknown as MouseEvent;

      setState((prev) => ({
        ...prev,
        active: {
          ...prev.active,
          newValues: { ...prev.active.newValues, from: getSegmentFromPoint(clientX, clientY) },
        },
      }));
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    onActivation: ({ event }) => {
      const { touches } = event as unknown as TouchEvent;
      const { clientX, clientY } = touches[0];

      setState((prev) => ({
        ...prev,
        active: {
          ...prev.active,
          newValues: { ...prev.active.newValues, from: getSegmentFromPoint(clientX, clientY) },
        },
      }));
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  return (
    <DndContext sensors={sensors}>
      <SchedulerGrid />
    </DndContext>
  );
};
