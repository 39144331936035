import { TextHighlighter } from '@components/textHighlighter';
import { Button } from '@components/ui/button';
import { Card, CardContent, CardHeader } from '@components/ui/card';
import { Form, FormField, FormMessage } from '@components/ui/form';
import { Input } from '@components/ui/form/input';
import { FormItemWrapper } from '@components/ui/form/itemWrapper';
import { PasswordInput } from '@components/ui/form/password';
import { useLogInForm } from '@hooks/forms/useLogInForm';
import { useQueryParams } from '@hooks/queryParams';
import { appRoutes } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export const SignIn = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const {
    params: { redirect },
  } = useQueryParams();

  const { form, onSubmit, locked } = useLogInForm({
    successHandler: () => {
      navigate(parseRoute(redirect ? `/${redirect}` : appRoutes.index));
    },
  });

  return (
    <Card className="mx-auto max-w-md rounded-lg border-0 font-inter shadow-card">
      <CardHeader className="flex-row">
        <h1 className="text-heading-xs font-bold">{t('pages.login.title')}</h1>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItemWrapper label={t('forms.email.label')}>
                    <Input {...field} />
                  </FormItemWrapper>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItemWrapper label={t('forms.password.label')}>
                    <PasswordInput {...field} />
                  </FormItemWrapper>
                )}
              />
              <FormField name="root" render={() => <FormMessage />} />
            </div>
            <div className="flex justify-end gap-1">
              <Button type="submit" disabled={locked}>
                {t('pages.login.action')}
              </Button>
            </div>
            <div>
              <Link className="link" to={`/${parseRoute(appRoutes.passwordRecovery)}`}>
                <TextHighlighter text={t('pages.login.link')} wordsIndexes={[1]} />
              </Link>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
