import * as React from 'react';

import { cn } from '@utils/cn';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  wrapperClassName?: string;
  prefixElement?: React.ReactNode;
  suffixElement?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, wrapperClassName, type, id, error, prefixElement, suffixElement, ...props },
    ref,
  ) => {
    return (
      <div
        className={cn(
          'group-[.error]:shadow-danger-b flex h-8 w-full items-center gap-2 rounded border border-neutral-300 bg-white px-3 text-s text-neutral-800 focus-within:border-primary-600 focus-within:outline focus-within:outline-2 focus-within:outline-primary-200',
          error ? 'border-danger-500 focus-within:border-danger-500' : '',
          props.readOnly ? 'border-neutral-300 text-neutral-800' : '',
          props.disabled ? 'border-neutral-200 bg-neutral-50 text-neutral-400' : '',
          className,
          wrapperClassName,
        )}>
        {prefixElement}
        <input
          id={id}
          type={type}
          onFocus={(e) => e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })}
          onWheel={(e) => {
            e.stopPropagation();
            e.currentTarget.blur();
          }}
          className={cn(
            'flex grow bg-transparent py-1 text-m leading-none ring-offset-background placeholder:text-neutral-400 focus-visible:outline-none disabled:cursor-not-allowed',
            className,
          )}
          ref={ref}
          {...props}
        />
        {suffixElement}
      </div>
    );
  },
);
// eslint-disable-next-line immutable/no-mutation
Input.displayName = 'Input';

export { Input };
