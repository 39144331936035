import { Button } from '@components/ui/button';
import { Separator } from '@components/ui/separator';
import { useTestWebPush } from '@services/webPushNotifications/test';

export const PushTest = () => {
  const { test } = useTestWebPush();

  return (
    <>
      <Separator />
      {'Notification' in window &&
        Notification.permission === 'granted' &&
        import.meta.env.VITE_PUSH_TEST && <Button onClick={() => test()}>Test push</Button>}
    </>
  );
};
