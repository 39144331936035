import { appRoutes, foremanRoute } from '@utils/consts';
import { foremanSharedRoutes } from '../shared/routes';

export const foremanArchiveRoutes = [
  {
    path: foremanRoute.archive,
    children: [
      {
        path: appRoutes.base,
        lazy: async () => {
          const { ForemanArchive } = await import('@pages/foreman/archive');
          return {
            Component: () => <ForemanArchive />,
          };
        },
      },
      foremanSharedRoutes,
    ],
  },
];
