import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { ApiError, Job, JobTaskStatus, Note, Pagy, Speciality } from '@root/globalTypes';
import { queryKeys } from '@utils/consts';
import { useQueryParams } from '@hooks/queryParams';
import { transformCamelCaseToSnakeCase } from '@utils/textHelpers';

export interface Schedule {
  id: string;
  foreman: {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  };
  endsAt: string;
  startsAt: string;
  status: string;
  task?: {
    id: string;
    job?: Job;
    name: string;
    status?: JobTaskStatus;
    multischedule?: boolean;
    multischeduleFirst?: boolean;
    multischeduleLast?: boolean;
    stage?: {
      id: string;
      name: string;
    };
    note?: Note;
  };
  note?: Note;
  warehouseTask?: {
    id: string;
    name: string;
    status: JobTaskStatus;
    warehouse?: {
      id: string;
      address: string;
      lat: string;
      lon: string;
      name: string;
    };
  };
}

export interface Schedules {
  id?: string;
  fullName?: string;
  schedules: Schedule[];
}

export interface SchedulesRespSuccess {
  success: true;
  records: Schedules[];
  withoutTeam: Schedule[];
  pagy?: Pagy;
}

export interface OpenSchedulesResp {
  success: true;
  records: Schedule[];
}

interface TeamsSchedules {
  date: string;
  active?: boolean;
  search?: string;
  foremanSpeciality?: Speciality;
  status?: string;
}

export const useTeamsSchedules = ({
  date,
  foremanSpeciality,
  status,
  active = true,
  search,
}: TeamsSchedules) => {
  const {
    params: { team, ...params },
    parseParams,
  } = useQueryParams();

  const parseSpeciality = () => {
    if (foremanSpeciality) return [transformCamelCaseToSnakeCase(foremanSpeciality)];
    return team?.map((el) => transformCamelCaseToSnakeCase(el)) || [];
  };

  const queryParams = parseParams({
    search: typeof search === 'string' ? search : params.search,
    date,
    status: status || params.status || '',
    foremanSpeciality: parseSpeciality() || '',
  });

  const { data, error, isFetching } = useQuery<SchedulesRespSuccess, ApiError>({
    queryKey: [...queryKeys.schedules, queryParams],
    queryFn: () =>
      queryFetch({
        url: `users_schedules${queryParams}`,
      }),
    placeholderData: (previousData) => previousData as SchedulesRespSuccess,
    enabled: active,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
