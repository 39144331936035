import { DefaultApiResp } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { useMutation } from '@tanstack/react-query';
import { useShowErrorToast } from '@utils/formHelpers';

export const useTestWebPush = () => {
  const { showErrorToast } = useShowErrorToast();

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<DefaultApiResp> =>
      queryFetch({
        url: 'webpush_notifications/push',
        data: { message: 'Test push message body' },
        opt: { method: 'POST' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSettled: (data) => {
      const { errors } = data as DefaultApiResp;
      if (errors) showErrorToast(errors);
    },
  });

  return {
    test: mutate,
    isLoading: isPending,
  };
};
